import React from 'react';
import { Modal, Form, Input } from 'antd';

const PartnerUserForm = ({
  visible,
  loading,
  onCreate,
  onCancel,
}: { visible: boolean; loading: boolean; onCreate: Function; onCancel: any }) => {
  const [form] = Form.useForm();
  return (
    // @ts-ignore
    <Modal
      visible={visible}
      title="Create a new partner user"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      okButtonProps={
        { loading }
      }
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
            form.resetFields();
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <div style={{ display: 'flex' }}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'Please input a first name!' }]}
            style={{ flex: 1, margin: '0 0.5rem 0 0' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Please input a last name!' }]}
            style={{ flex: 1, margin: '0 0 0 0.5rem' }}
          >
            <Input />
          </Form.Item>
        </div>
        <div style={{ display: 'flex' }}>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[{ required: true, message: 'Please input a phone number!' }]}
            style={{ flex: 1, margin: '1rem 0.5rem 0 0' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please input an email!' }]}
            style={{ flex: 1, margin: '1rem 0 0 0.5rem' }}
          >
            <Input />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default PartnerUserForm;
