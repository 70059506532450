import React from 'react';
import Amplify from 'aws-amplify';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import {
  BrowserRouter as Router,
  Route as PublicRoute,
  Switch,
} from 'react-router-dom';
import config from './config';
import AuthState from './contexts/auth/AuthState';
import NavState from './contexts/nav/NavState';

import Route from './components/_common/Route';
import Nav from './components/_common/Nav';
import './App.scss';
import IndexPage from './components/index';
import Login from './components/_common/Login';
import ScheduleList from './components/schedules/ScheduleList';
import PartnerList from './components/partners/PartnerList';
import PartnerDetail from './components/partners/PartnerDetail';
import OrderList from './components/orders/OrderList';
import MenuList from './components/menus/MenuList';
import ScheduleDetail from './components/schedules/ScheduleDetail';
import UserList from './components/users/UserList';
import CouponList from './components/coupons/CouponList';

Amplify.configure(config.aws);

firebase.initializeApp(config.firebase);

function App() {
  return (
    <AuthState>
      <NavState>
        <Router>
          <Nav />
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <PublicRoute exact path="/login" component={Login} />
            <Route exact path="/schedules" component={ScheduleList} />
            <Route exact path="/schedules/:scheduleId" component={ScheduleDetail} />
            <Route exact path="/partners" component={PartnerList} />
            <Route exact path="/partners/:partnerId" component={PartnerDetail} />
            <Route exact path="/menus" component={MenuList} />
            <Route exact path="/orders" component={OrderList} />
            <Route exact path="/users" component={UserList} />
            <Route exact path="/coupons" component={CouponList} />
          </Switch>
        </Router>
      </NavState>
    </AuthState>
  );
}

export default App;
