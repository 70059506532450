/* eslint-disable no-unused-vars, no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {
  Divider, Checkbox, Modal, Form, Input, Switch, Col, Row, Typography, Alert,
} from 'antd';
// eslint-disable-next-line no-unused-vars
import { Menu, SelectionType } from '../../common/types';

const { Title } = Typography;
const { TextArea } = Input;

const statusMap = {
  APPROVED: 'success',
  PENDING_REVIEW: 'warning',
  DISABLED: 'error',
  ADJUSTMENT_NEEDED: 'error',
};

const parseItems = (menu?: Menu): any[] => {
  const items: any[] = [];

  if (!menu) {
    return [];
  }

  menu.categories!.forEach((category) => category.items!.forEach((item) => {
    const prices: number[] = [];
    item.variations?.forEach((variant) => {
      // TODO Possibly remove price division by 100 when displaying
      // The test data seems to store the cents as part of the number not as a decimal
      const price = variant.item_variation_data!.price_money
        ? (variant.item_variation_data!.price_money!.amount! / 100) : 0;
      if (!prices.includes(price)) {
        prices.push(price);
      }
    });
    prices.sort((a, b) => a - b);
    const priceWithNoRange = prices.length === 0 ? '$0.00' : `$${prices[0].toFixed(2)}`;
    items.push({
      id: item.id!,
      name: item.name!,
      category: category.name,
      price: prices.length > 1 && prices[0]?.toFixed(2) && prices[prices.length - 1]?.toFixed(2)
        ? `$${prices[0].toFixed(2)} - $${prices[prices.length - 1].toFixed(2)}`
        : priceWithNoRange,
      menus: item.menus!.sort(),
      loading: false,
      ...item,
    });
  }));
  items.sort((a, b) => {
    if (a.category < b.category) {
      return -1;
    } if (a.category === b.category) {
      return 0;
    }
    return 1;
  });
  return items.map((item, index) => ({ ...item, index }));
};

const MenuForm = ({
  visible,
  loading,
  onSubmit,
  onCancel,
  menu,
  // eslint-disable-next-line react/require-default-props
}: { visible: boolean; loading: boolean; onSubmit: Function; onCancel: any; menu?: Menu }) => {
  const [form] = Form.useForm();

  const [items, setItems] = useState<any[]>(parseItems(menu));
  const [menuName, setMenuName] = useState<string>();

  const [activeItem, setActiveItem] = useState<string>();
  useEffect(() => {
    const parsedItems = parseItems(menu);
    setItems(parsedItems);
  }, [menu]);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setMenuName(menu?.name);
        form.resetFields();
      });
    }
  }, [visible]);

  function setValue(prev, cur) {
    if (cur.length) {
      const formValue = {};
      cur.forEach((field) => {
        if (field.value && field.value.length) {
          const split = field.name[0].split('#');
          const modifierName: string = split[0];
          const selectionType: SelectionType = split[1];
          if (selectionType === SelectionType.SINGLE && activeItem === modifierName) {
            // set value to last clicked item
            formValue[`${modifierName}#${selectionType}`] = [field.value[field.value.length - 1]];
          }
          setActiveItem(modifierName);
        }
      });
      form.setFieldsValue(formValue);
    }
  }

  return (
    // @ts-ignore
    <Modal
      visible={visible}
      width={900}
      title="Review Menu"
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      destroyOnClose
      okButtonProps={
        { loading }
      }
      onOk={() => {
        let needsAdjustments;
        form
          .validateFields()
          .then((values) => {
            const keys = Object.keys(values);
            keys.forEach((k) => {
              if (k.includes('isApproved')) {
                const itemIndex = k.split('_')[1];
                if (!values[k]) { needsAdjustments = true; }
                items[itemIndex].isApproved = values[k] || false;
              } else if (k.includes('reviewNotes')) {
                const itemIndex = k.split('_')[1];
                items[itemIndex].reviewNotes = values[k] || false;
              }
            });
            onSubmit({ items, needsAdjustments });
            form.resetFields();
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        onFieldsChange={setValue}
        initialValues={{
          name: menuName,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Title is required!' }]}
            style={{ flex: 1, margin: '0 0.5rem 0 0' }}
          >
            <Input />
          </Form.Item>
          {menu && <Alert style={{ textAlign: 'center' }} message={menu.status} type={statusMap[menu.status!] as any} />}
        </div>
        <div>
          <div>
            {items.map((item, itemIndex) => (
              <div key={item.id}>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                  <div style={{ width: '50%' }}>
                    <Title style={{ whiteSpace: 'pre' }} level={4}>{`${item.name}         ${item.price}`}</Title>
                    <div style={{ marginLeft: '1rem' }}>
                      <Form.Item
                        name={`${item.name}Variation#SINGLE`}
                        // rules={[{
                        //   required: item.variations.length !== 1,
                        //   message: 'Please select a variation',
                        // }]}
                        style={{ display: item.variations?.length === 1 ? 'none' : 'block' }}
                      >
                        <Checkbox.Group>
                          <Row>
                            {item.variations?.map((v) => (
                              <Col span={12} key={v.id}>
                                <Checkbox
                                  value={v}
                                  // style={{
                                  //   lineHeight: '32px',
                                  // }}
                                  onClick={(e) => { e.preventDefault(); }}
                                >
                                  {v.item_variation_data.name}
                                  &emsp;
                                  {
                                    // eslint-disable-next-line no-nested-ternary
                                    v.item_variation_data.price_money
                                      ? (v.item_variation_data.price_money?.amount
                                        > item.variations[0].item_variation_data.price_money?.amount
                                        ? `+ $${((v.item_variation_data.price_money?.amount - item.variations[0].item_variation_data.price_money?.amount) / 100).toFixed(2)}` : null
                                      ) : null
                                  }
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>

                      {
                        item.modifiers.map((modifier) => (
                          <Form.Item
                            key={modifier.id}
                            name={`${item.name}${modifier.name}#${modifier.selection_type}`}
                            label={modifier.name}
                          // rules={[{
                          // required: modifier.min_selected_modifiers > 0,
                          // message: `Please select ${modifier.name}!`,
                          // }]}
                          >
                            <Checkbox.Group>
                              <Row>
                                {modifier.modifiers.map((m) => (
                                  <Col span={12} key={m.id}>
                                    <Checkbox
                                      value={m}
                                    // style={{
                                    //   lineHeight: '32px',
                                    // }}
                                    // onClick={(e) => { e.preventDefault(); }}
                                    >
                                      {m.name}
                                      &emsp;
                                      {m.price_money && m.price_money.amount ? `+ $${(m.price_money.amount / 100).toFixed()}` : null}
                                    </Checkbox>
                                  </Col>
                                ))}
                              </Row>
                            </Checkbox.Group>
                          </Form.Item>
                        ))
                      }
                    </div>

                  </div>

                  <div
                    key={item.id}
                    style={{
                      display: 'flex', flexDirection: 'column', alignItems: 'center',
                    }}
                  >
                    <Form.Item
                      name={`isApproved_${itemIndex}`}
                      label="Declined/Approved"
                      style={{ textAlign: 'center' }}
                      initialValue={item.isApproved}
                    >
                      <Switch
                        defaultChecked={item.isApproved}
                      />
                    </Form.Item>
                    <Form.Item
                      name={`reviewNotes_${itemIndex}`}
                      label={<span style={{ marginLeft: '20px' }}>Adjustments Needed</span>}
                      rules={[{ required: false, message: 'Please input a note!' }]}
                      style={{ textAlign: 'center' }}
                    >
                      <TextArea rows={3} style={{ width: '192px' }} />

                    </Form.Item>
                  </div>
                  <div style={{
                    display: 'flex', flexDirection: 'column', width: '250px', alignItems: 'flex-end',
                  }}
                  >
                    <div style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                      Previous Adjustments
                    </div>
                    {item.reviewNotes}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div />
      </Form>
    </Modal>
  );
};

export default MenuForm;
