const dev = {
  aws: {
    API: {
      apiKey: 'p5wIgEHaezT2BQ7LWbtylm9oXwR9KXKOeg0vBSWQY0wSdYZdplB4wkNqpAnrvnzR',
      endpoints: [
        {
          name: 'CurbsideRestAPI',
          endpoint: 'https://api.dev.curbsidekitchen.com',
          // endpoint: 'https://api.personal.curbside.3a-dev-ms.apps.3advance.co',

        },
      ],
    },
  },
  firebase: {
    apiKey: 'AIzaSyA5Ci1BZxsfFTvKLaOMz3-kBwkaBuRVec0',
    authDomain: 'curbside-kitchen-app-dev.firebaseapp.com',
    databaseURL: 'https://curbside-kitchen-app-dev.firebaseio.com',
    projectId: 'curbside-kitchen-app-dev',
    storageBucket: 'curbside-kitchen-app-dev.appspot.com',
    messagingSenderId: '529291972701',
    appId: '1:529291972701:web:37b1a3a6dfb7528f413db4',
    measurementId: 'G-T0YHJ4Q8KN',
  },
  square: {
    endpoint: 'https://connect.squareupsandbox.com',
    clientId: 'sandbox-sq0idb-FbeIr-7AXu1h0cpkuQ4KTg',
  },
};

const prod = {
  aws: {
    API: {
      apiKey: 'uKBmMPtUEL5WdBniwtgSTi1okOWXuvEgz4yY0jz6C8OabqJmhVrCGP6GYEBYQNDq',
      endpoints: [
        {
          name: 'CurbsideRestAPI',
          endpoint: 'https://api.prod.curbsidekitchen.com',
        },
      ],
    },
  },
  firebase: {
    apiKey: 'AIzaSyB-aMBvgdoqh5cUfpuHkOFogrtehVVVQZw',
    authDomain: 'curbside-kitchen-app-prod.firebaseapp.com',
    databaseURL: 'https://curbside-kitchen-app-prod.firebaseio.com',
    projectId: 'curbside-kitchen-app-prod',
    storageBucket: 'curbside-kitchen-app-prod.appspot.com',
    messagingSenderId: '312035995973',
    appId: '1:312035995973:web:121e663401bdcf78484e2c',
    measurementId: 'G-FJCDFP4JYE',
  },
  square: {
    applicationId: 'sq0idp-ptlz_wLEvDIxug-OFNGTjw',
  },
};

const config: any = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default config;
