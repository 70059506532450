import React, { useContext, useEffect } from 'react';
import { Button } from 'antd';
import { Redirect } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { AuthContext } from '../../common/types';
import { authContext as _authContext } from '../../contexts/auth/authContext';
import Loading from './Loading';

const Login = () => {
  const authContext: AuthContext = useContext(_authContext);
  const initAuth = authContext.initAuth!;

  const {
    auth, appLoading, authInit, isSignIn,
  } = authContext;

  useEffect(() => {
    if (!authInit) {
      initAuth();
    }
  }, []);

  return (
    <>
      {
        isSignIn || appLoading ? <Loading /> : (
          <div className="login-container">
            <Button
              onClick={() => authContext.signInWithGoogle()}
            >
              Sign in with Google
            </Button>
          </div>
        )
      }
      {auth && !auth.isAnonymous ? <Redirect to="/schedules" /> : null}
    </>
  );
};

export default Login;
