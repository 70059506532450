/* eslint-disable react/require-default-props, no-unused-vars */
import React, {
  useEffect, useState,
} from 'react';
import moment from 'moment';
import { Table, message, Button } from 'antd';
import * as apiClient from '../../common/api-client';
import { Coupon } from '../../common/types';
import { navContext as _navContext } from '../../contexts/nav/navContext';
import CouponForm from './CouponForm';

const CouponList = () => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const loadCoupons = async () => {
    try {
      const data: Coupon[] = await apiClient.listAllCoupons();

      setCoupons(data);
      setIsFetching(false);
    } catch (error: any) {
      message.error(error.message);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    loadCoupons();
  }, []);

  const columns: any[] = [
    {
      title: 'Code',
      key: 'code',
      render: (c) => `${c.code || ''}`,
    },
    {
      title: 'Value',
      key: 'value',
      render: (c) => `${c.couponType === 'FIXED' ? '$' : ''}${c.value || ''}${c.couponType === 'PERCENTAGE' ? '%' : ''}`,
    },
    {
      title: 'Start Date',
      key: 'role',
      render: (c) => `${moment(c.startDate).format('MMM Do YY')}`,
    },
    {
      title: 'Expired Date',
      key: 'expires',
      render: (c) => `${moment(c.expiredDate).format('MMM Do YY')}`,
    },
    {
      title: 'Max Uses',
      key: 'maxUses',
      render: (c) => `${c.maxUses}`,
    },
    {
      title: 'Billable',
      key: 'isBillable',
      render: (c) => `${c.isBillable ? 'Yes' : 'No'}`,
    },
    {
      title: 'Total Uses',
      key: 'totalUses',
      render: (c) => c.totalUses || 0,
    },
  ];

  const onCouponCreate = async (values) => {
    try {
      setModalLoading(true);
      const coupon = await apiClient.createCoupon(values);
      setCoupons([...coupons, coupon]);
      message.success('Coupon created.');
      setModalLoading(false);
    } catch (error) {
      message.error('Error creating coupon.');
      setModalLoading(false);
    }
    setModalOpen(false);
  };

  return (
    <>
      <div className="index-menu-tab-input list-actions">
        {!isFetching && (
          <Button onClick={() => setModalOpen(true)} style={{ marginRight: '16px' }}>Add Coupon</Button>
        )}
      </div>
      <Table
        dataSource={coupons}
        columns={columns}
        rowKey="id"
        rowClassName="table-row"
        loading={isFetching}
        style={{ margin: '1rem' }}
      />
      <CouponForm
        visible={modalOpen}
        onCreate={onCouponCreate}
        onCancel={() => {
          setModalOpen(false);
        }}
        loading={modalLoading}
      />
    </>
  );
};

export default CouponList;
