/* eslint-disable react/require-default-props, import/no-unresolved, react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  PageHeader, Descriptions, Tabs, Spin, InputNumber, Button, message,
} from 'antd';
import { SaveOutlined, CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Redirect } from 'react-router-dom';
import * as apiClient from '../../common/api-client';
// eslint-disable-next-line no-unused-vars
import { Partner } from '../../common/types';

// import UserList from '../users/UserList';
import OrderList from '../orders/OrderList';
import SchedulePartnerList from '../schedulePartners/SchedulePartnerList';
import MenuDetail from './MenuDetail';
import UserList from '../users/UserList';

const PartnerDetail = ({ match }: {
  match?: { params: { partnerId } },
}) => {
  const [partner, setPartner] = useState<Partner | any>({});
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [partnerFeeLoading, setPartnerFeeLoading] = useState<boolean>(false);
  const [newPartnerFeeValue, setNewPartnerFeeValue] = useState<number>();
  const [ordersPerQueueSlotLoading, setOrdersPerQueueSlotLoading] = useState<boolean>(false);
  const [newOrdersPerQueueSlotValue, setNewOrdersPerQueueSlotValue] = useState<number>();
  const [refreshingToken, setRefreshingToken] = useState<boolean>(false);

  const [back, setBack] = useState(false);
  const { TabPane } = Tabs;

  const loadPartner = async () => {
    const { partnerId } = match!.params;
    const data = await apiClient.getPartner(partnerId);
    setPartner(data);
    setIsFetching(false);
  };

  useEffect(() => { loadPartner(); }, []);

  const detailHeader = () => (
    <PageHeader
      style={{ backgroundColor: '#fff' }}
      onBack={() => setBack(true)}
      title={partner.name}
    />
  );

  const onCopy = () => {
    message.success(`"${partner.dynamicLink}" copied to clipboard`);
  };

  const detailBody = () => (
    <Descriptions bordered size="small" column={2} style={{ flex: 1, margin: '0 3rem 2rem 3rem' }}>
      <Descriptions.Item label="Default Partner Fee">
        {partner.partnerFee ? `%${partner.partnerFee * 100}` : 'None'}
      </Descriptions.Item>
      <Descriptions.Item label="Default Orders Per 15 Min Queue Slot">
        {partner.ordersPerQueueSlot ? `${partner.ordersPerQueueSlot}` : 'None'}
      </Descriptions.Item>
      <Descriptions.Item label="Taxes">
        <div>
          {(partner.menu && partner.menu.taxes) ? partner.menu.taxes.map((t) => (
            <div key={t.id}>
              {`${t.tax_data.name} %${t.tax_data.percentage} (${t.tax_data.inclusion_type})`}
            </div>
          )) : 'None'}
        </div>
      </Descriptions.Item>
      <Descriptions.Item label="Default Menu">
        {partner.defaultMenuName ? partner.defaultMenuName : 'None'}
      </Descriptions.Item>
      <Descriptions.Item label="Schedules">
        {partner.schedules.length}
      </Descriptions.Item>
      <Descriptions.Item label="Connected Square Location">
        {partner.merchant ? partner.merchant.locations
          .filter((location) => location.id === partner.merchant.locationId)
          .map((p) => p.name) : 'Not Connected'}
      </Descriptions.Item>
      <Descriptions.Item label="Partner Access Dynamic Link">
        {partner.dynamicLink ? partner.dynamicLink : 'None'}
        <CopyToClipboard onCopy={onCopy} text={partner.dynamicLink}>
          <span>
            &emsp;
            <Button shape="circle" icon={<CopyOutlined />} />
          </span>
        </CopyToClipboard>
      </Descriptions.Item>
    </Descriptions>
  );

  const onPartnerFeeSet = async () => {
    try {
      setPartnerFeeLoading(true);
      await apiClient.updatePartner(partner.id, { partnerFee: newPartnerFeeValue! });
      setPartnerFeeLoading(false);
      message.success('Default Partner Fee updated');
    } catch (error) {
      setPartnerFeeLoading(false);
      message.success('Error updating Partner Fee');
    }
  };

  const onOrdersPerQueueSlotSet = async () => {
    try {
      setOrdersPerQueueSlotLoading(true);
      await apiClient.updatePartner(
        partner.id, { ordersPerQueueSlot: newOrdersPerQueueSlotValue! },
      );
      setOrdersPerQueueSlotLoading(false);
      message.success('Default Orders per Queue Slot updated');
    } catch (error) {
      setOrdersPerQueueSlotLoading(false);
      message.success('Error updating Orders per Queue Slot Fee');
    }
  };

  const refreshToken = async () => {
    setRefreshingToken(true);
    try {
      await apiClient.refreshPartnerSquareToken(partner.id);
      message.success('Square token refreshed!');
      setRefreshingToken(false);
    } catch (error) {
      message.error('Error refreshing token!');
      setRefreshingToken(false);
    }
  };

  const renderBack = () => {
    if (back) {
      return <Redirect to="/partners" />;
    }
    return null;
  };

  return (
    partner && !isFetching ? (
      <div style={{ padding: '0rem 1rem' }}>
        {isFetching && <Spin />}
        {renderBack()}
        {detailHeader()}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {detailBody()}
          <div style={{ marginRight: '3rem', width: '14rem' }}>
            <Button
              style={{ width: '100%', marginTop: '1rem' }}
              onClick={() => refreshToken()}
              loading={refreshingToken}
            >
              Refresh Square Token
            </Button>
          </div>
          <div style={{ marginRight: '55px' }}>
            Default Partner Fee
            <div style={{ display: 'flex', marginBottom: '1rem' }}>
              <InputNumber
                defaultValue={partner.partnerFee * 100 || 0}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => Number(value!.replace('%', ''))}
                onChange={(value) => {
                  if (value) {
                    setNewPartnerFeeValue(value as number / 100);
                  }
                }}
              />
              <Button
                type="primary"
                style={{ marginLeft: '2px' }}
                onClick={() => onPartnerFeeSet()}
                loading={partnerFeeLoading}
                disabled={!newPartnerFeeValue}
              >
                <SaveOutlined />
              </Button>
            </div>
            <span>
              Default Orders Per
              <br />
              15 Min Queue Slot
            </span>
            <div style={{ display: 'flex' }}>
              <InputNumber
                defaultValue={partner.ordersPerQueueSlot || 0}
                min={0}
                max={100}
                onChange={(value) => {
                  if (value) {
                    setNewOrdersPerQueueSlotValue(value as number);
                  }
                }}
              />
              <Button
                type="primary"
                style={{ marginLeft: '2px' }}
                onClick={() => onOrdersPerQueueSlotSet()}
                loading={ordersPerQueueSlotLoading}
                disabled={!newOrdersPerQueueSlotValue}
              >
                <SaveOutlined />
              </Button>
            </div>
          </div>
        </div>
        <Tabs>
          <TabPane tab="Schedules" key="schedules">
            <SchedulePartnerList partner={partner} />
          </TabPane>
          <TabPane tab="Orders" key="orders">
            <OrderList partner={partner} />
          </TabPane>
          <TabPane tab="Menu" key="menu">
            <MenuDetail partner={partner} reloadPartner={loadPartner} />
          </TabPane>
          <TabPane tab="Users" key="users">
            <UserList partner={partner} />
          </TabPane>
        </Tabs>
      </div>
    ) : (null)
  );
};

export default PartnerDetail;
