import React, { useState } from 'react';
import uuidv4 from 'uuid/dist/v4';
import {
  Modal, List, Typography, Button, message, Popconfirm,
} from 'antd';

// eslint-disable-next-line no-unused-vars
import { Order } from '../../common/types';

import * as apiClient from '../../common/api-client';

interface Props {
  // eslint-disable-next-line react/require-default-props
  detail?: {
    orderNumFormatted: number,
    couponCode?: string,
    order: Order,
    refund?: any,
    payment?: any
  }
  visible: boolean,
  onCancel: any
}

const { Title } = Typography;

const OrderDetail = ({
  detail, visible, onCancel,
}: Props) => {
  const [refundLoading, setRefundLoading] = useState(false);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const onRefundOrder = async (reason: string) => {
    try {
      const order: any = detail;
      setRefundLoading(true);

      await apiClient.refundOrder(
        order.partnerId!,
        order.scheduleId!,
        order.id!,
        {
          reason,
          key: uuidv4().replace(/-/g, ''),
        },
      );
      message.success('Order Refuned');
      setRefundLoading(false);
    } catch (error) {
      message.error('Error refunding order.');
      setRefundLoading(false);
    }
  };

  return (
    // @ts-ignore
    <Modal
      title={
        (
          <div>
            Order
            <span> </span>
            {detail && detail.orderNumFormatted}
          </div>
        )
      }
      visible={visible}
      onCancel={onCancel}
      onOk={onCancel}
      footer={
        (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {
              detail && !detail.refund
                ? (

                  <Popconfirm
                    title="Are you sure you want to refund this order?"
                    onConfirm={() => onRefundOrder('Order Refund')}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button loading={refundLoading}>
                      Refund
                    </Button>
                  </Popconfirm>
                ) : <div />
            }
            <Button type="primary" onClick={onCancel}>
              Close
            </Button>
          </div>
        )
      }
    >
      {detail && detail.order
        && (
          <List
            // bordered
            dataSource={detail.order.line_items}
            itemLayout="vertical"
            footer={
              (
                <Title level={4}>
                  {detail.couponCode && (
                  <Typography.Text>
                    <div style={{
                      display: 'flex', justifyContent: 'space-between', fontSize: '14px', marginBottom: '14px',
                    }}
                    >
                      <div>
                        Coupon(
                        {detail.couponCode}
                        )
                      </div>
                      {detail.order.discounts && (
                      <div style={{ color: 'red' }}>
                        {' '}
                        -
                        {formatter.format(detail.order.discounts[0].amount_money.amount / 100)}
                      </div>
                      )}
                    </div>
                  </Typography.Text>
                  )}
                  {detail.payment && detail.payment.tip_money && (
                  <Typography.Text>
                    <div style={{
                      display: 'flex', justifyContent: 'space-between', fontSize: '14px', marginBottom: '14px',
                    }}
                    >
                      <div>Tip</div>
                      {/* { isFetching ? <Loading disablePaddingTop={true} /> : couponValue} */}
                      {detail.payment && detail.payment.tip_money && (
                      <div style={{ color: 'green' }}>
                        {' '}
                        {formatter.format(detail.payment.tip_money.amount / 100)}
                      </div>
                      )}
                    </div>
                  </Typography.Text>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      Total
                    </div>
                    <div>
                      {(!detail.payment || !detail.payment?.total_money) && '$0'}
                      {detail.payment && detail.payment.total_money
                      && formatter.format(detail.payment.total_money.amount / 100)}
                    </div>
                  </div>
                </Title>
              )
            }
            rowKey="uid"
            renderItem={(item: any) => (
              <List.Item>
                <Typography.Text strong>

                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>{`${item.name} ${item.variation_name ? `(${item.variation_name})` : ''}`}</div>
                    <div>{formatter.format(item.base_price_money.amount / 100)}</div>
                  </div>
                </Typography.Text>
                <List.Item.Meta
                  description={
                    (
                      <div>
                        {item.modifiers && item.modifiers.map((m) => (
                          <div key={m.uid} style={{ display: 'flex' }}>
                            <div style={{ flex: '1', width: '50%' }}>
                              {m.name}
                            </div>
                            <div style={{ flex: '1', width: '50%', textAlign: 'right' }}>
                              {`${m.total_price_money.amount ? `${formatter.format(m.total_price_money.amount / 100)}` : ''}`}
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  }
                />
              </List.Item>
            )}
          />
        )}
    </Modal>
  );
};

export default OrderDetail;
