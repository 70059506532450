import { API } from 'aws-amplify';
import * as firebase from 'firebase/app';
import config from '../config';
import {
  // eslint-disable-next-line no-unused-vars
  Partner, Schedule, SchedulePartner, Menu, MenuStatus, MenuItem,
} from './types';

const API_NAME = 'CurbsideRestAPI';

interface Headers {
  'X-Api-Key': string;
  Authorization?: string;
}

async function getHeaders(): Promise<Headers> {
  const headers: Headers = {
    'X-Api-Key': config.aws.API.apiKey,
  };

  let firebaseToken;

  const { currentUser } = firebase.auth();

  if (currentUser) {
    firebaseToken = await currentUser.getIdToken();
  }

  if (firebaseToken) {
    headers.Authorization = firebaseToken;
  }
  return headers;
}

export async function getApiMe(): Promise<any> {
  return API.get(API_NAME, '/me', { headers: await getHeaders() });
}

export async function getEmailLink(body): Promise<any> {
  return API.post(API_NAME, '/partners/email-link', {
    body,
    headers: await getHeaders(),
  });
}

export async function listAllSchedules(): Promise<Schedule[]> {
  // const d = new Date();
  return API.get(API_NAME, '/schedules', {
    headers: await getHeaders(),
    // queryStringParameters: {
    //   point: '38.9072,-77.0369',
    //   radius: 20,
    //   date: new Date(d.getTime() - (d.getTimezoneOffset() * 60000))
    //     .toISOString()
    //     .split('T')[0],
    // },
  });
}

export async function listPartners(): Promise<Partner[]> {
  return API.get(API_NAME, '/partners', { headers: await getHeaders() });
}

export async function getPartner(partnerId: string): Promise<Partner> {
  return API.get(API_NAME, `/partners/${partnerId}`, { headers: await getHeaders() });
}

export async function updatePartner(
  partnerId: string,
  body: { partnerFee?: number, ordersPerQueueSlot?: number },
): Promise<void> {
  return API.patch(API_NAME, `/partners/${partnerId}`, {
    headers: await getHeaders(),
    body,
  });
}

export async function setSchedulePartnerProp(
  schedulePartner: SchedulePartner,
  prop: string,
): Promise<void> {
  return API.patch(API_NAME, `/partners/${schedulePartner.food_truck}/schedules/${schedulePartner.schedule}`, {
    headers: await getHeaders(),
    body: { [prop]: schedulePartner[prop] },
  });
}

export async function getSchedule(id: string): Promise<any> {
  return API.get(API_NAME, `/admin/schedules/${id}`, { headers: await getHeaders() });
}

export async function getSchedulePartner(scheduleId: string, partnerId: string): Promise<any> {
  return API.get(API_NAME, `/schedules/${scheduleId}/partners/${partnerId}`, { headers: await getHeaders() });
}

export async function updateSchedule(
  scheduleId: string,
  body: { customerFee?: number, orderAheadDays?: number },
): Promise<void> {
  return API.patch(API_NAME, `/schedules/${scheduleId}`, {
    headers: await getHeaders(),
    body,
  });
}

export async function listAllOrders(): Promise<any> {
  return API.get(API_NAME, '/orders', { headers: await getHeaders() });
}

export async function listSchedulePartnerOrders(
  scheduleId: string, partnerId: string,
): Promise<any> {
  return API.get(
    API_NAME, `/partners/${partnerId}/schedules/${scheduleId}/orders`,
    { headers: await getHeaders() },
  );
}

export async function addMenuItem(
  partnerId: string,
  menuName: string,
  itemId: string,
): Promise<any> {
  return API.post(API_NAME, `/partners/${partnerId}/menus/${menuName}/${itemId}`, {
    headers: await getHeaders(),
  });
}

export async function deleteMenuItem(
  partnerId: string,
  menuName: string,
  itemId: string,
): Promise<any> {
  return API.del(API_NAME, `/partners/${partnerId}/menus/${menuName}/${itemId}`, {
    headers: await getHeaders(),
  });
}

export async function refreshMenu(partnerId: string): Promise<any> {
  return API.put(API_NAME, `/partners/${partnerId}/square/refresh`, { headers: await getHeaders() });
}

export async function listAllUsers(): Promise<any> {
  return API.get(API_NAME, '/admin/users', { headers: await getHeaders() });
}

export async function listUsersForPartner(partnerId: string): Promise<any> {
  return API.get(API_NAME, '/admin/users', {
    headers: await getHeaders(),
    queryStringParameters: {
      partnerId,
    },
  });
}

export async function createPartnerUser(
  partnerId: string,
  body: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    id: string;
  },
): Promise<any> {
  return API.post(API_NAME, `/partners/${partnerId}/users`, {
    headers: await getHeaders(),
    body,
  });
}

export async function refundOrder(
  partnerId: string,
  scheduleId: string,
  orderId: string,
  body: { reason: string; key: string },
): Promise<any> {
  return API.post(API_NAME, `/partners/${partnerId}/schedules/${scheduleId}/orders/${orderId}/refund`, {
    headers: await getHeaders(),
    body,
  });
}

export async function listPartnerSquareOrders(
  partnerId: string,
  scheduleId: string,
): Promise<any> {
  return API.get(API_NAME, `/partners/${partnerId}/schedules/${scheduleId}/square-orders`, {
    headers: await getHeaders(),
    // queryStringParameters: { dateFrom, dateTo },
  });
}

export async function listPartnerSquarePayments(
  partnerId: string,
  dateFrom: string,
  dateTo: string,
): Promise<any> {
  return API.get(API_NAME, `/partners/${partnerId}/square-payments`, {
    headers: await getHeaders(),
    queryStringParameters: { dateFrom, dateTo },
  });
}

export async function listAllCoupons(): Promise<any> {
  return API.get(API_NAME, '/coupons', { headers: await getHeaders() });
}

export async function createCoupon(body): Promise<any> {
  return API.post(API_NAME, '/coupons', {
    headers: await getHeaders(),
    body,
  });
}

export async function checkInOutForEvent(
  partnerId: string,
  scheduleId: string,
  body,
): Promise<any> {
  return API.patch(API_NAME, `/partners/${partnerId}/schedules/${scheduleId}`, {
    headers: await getHeaders(),
    body,
  });
}

export async function updateSchedulePartnerMenu(
  schedulePartner, body: { enabledItem?: string, disabledItem?: string },
): Promise<any> {
  return API.patch(API_NAME, `/partners/${schedulePartner.food_truck}/schedules/${schedulePartner.schedule}`, {
    headers: await getHeaders(),
    body,
  });
}

export async function sendSMSMessage(
  buildingId: string, scheduleId: string, message: string,
): Promise<any> {
  return API.post(API_NAME, `/admin/schedules/${scheduleId}/messages`, {
    headers: await getHeaders(),
    body: { message, buildingId },
  });
}

export async function getScheduleReporting(scheduleId: string, isFullDay?: boolean): Promise<any> {
  return API.get(API_NAME, `/admin/schedules/${scheduleId}/reporting${isFullDay ? '?isFullDay=true' : ''}`, {
    headers: await getHeaders(),
  });
}

export async function getDateRangeReporting(
  dateFrom: string, dateTo: string, markets: string,
): Promise<any> {
  return API.get(API_NAME, '/admin/reporting', {
    headers: await getHeaders(),
    queryStringParameters: { dateFrom, dateTo, markets },
  });
}

export async function listAllMenus(): Promise<any> {
  return API.get(API_NAME, '/admin/menus', { headers: await getHeaders() });
}

export async function updateMenu(request: {
  status: MenuStatus, id: string, items: MenuItem[], partnerId: string,
}): Promise<any> {
  return API.patch(API_NAME, `/partners/${request.partnerId}/menus/${request.id}`, {
    headers: await getHeaders(),
    body: request,
  });
}

export async function addMenu(
  partnerId: string, name: string, items: MenuItem[],
): Promise<Menu> {
  return API.post(API_NAME, `/partners/${partnerId}/menus`, {
    headers: await getHeaders(),
    body: { name, items },
  });
}

export async function refreshPartnerSquareToken(partnerId: string): Promise<any> {
  return API.post(API_NAME, `/admin/partners/${partnerId}/refresh-square-token`, {
    headers: await getHeaders(),
  });
}

export async function listMenus(
  partnerId: string,
): Promise<Menu[]> {
  return API.get(API_NAME, `/partners/${partnerId}/menus`, {
    headers: await getHeaders(),
  });
}
