/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

export interface SchedulePartner {
    id?: string;
    buildingName?: string;
    buildingPickupArea?: string;
    eventName?: string;
    date?: string;
    updated_at?: string;
    cancel_reason_fta?: string;
    deleted?: string;
    status?: string;
    status_order?: number;
    customerFee?: number;
    partnerFee?: number;
    taxId?: number;
    taxPercentage?: number;
    taxInclusionType?: number;
    accepting_comment?: string;
    payment_status?: string;
    update_time?: string;
    status_update_time?: string;
    token_by_fta?: string;
    is_refund_truck?: boolean;
    is_immediate_opening_email?: boolean;
    is_immediate_opening_sms?: boolean;
    truck_assignment?: boolean;
    model_run_id?: number;
    predicted_ticket_sales?: number;
    predicted_success?: number;
    schedule?: string;
    food_truck?: string;
    menu?: any;
    isCheckedIn?: boolean;
    selectedMenu?: string;
    menuApproved?: string;
    numOrders: number;
    numOrdersPrepared: number;
    location?: string;
    scheduleName?: string;
    comment?: string;
    food_time_display?: string;
    tag?: string;
    time_arrival?: string;
    time_end_format?: string;
    time_start_format?: string;
    utc_end?: string;
    utc_start?: string;
    estimateWaitMins?: number;

    selectedMenuSaveLoading?: boolean;
    selectedMenuSaveEnabled?: boolean;
    taxIdSaveLoading?: boolean;
    taxIdSaveEnabled?: boolean;
    partnerFeeSaveLoading?: boolean;
    partnerFeeSaveEnabled?: boolean;
    customerFeeSaveLoading?: boolean;
    customerFeeSaveEnabled?: boolean;

    menuApprovedSaveLoading?: boolean;
    menuApprovedSaveEnabled?: boolean;

    menus: string[];
    food_trucks: any;
}

export enum OrderType {
    ASAP = 'ASAP',
    AHEAD = 'AHEAD'
}

export interface AuthContext {
    appLoading?: boolean;
    authLoading?: boolean;
    meLoading?: boolean;
    authInit?: boolean;
    initAuth?: Function;
    auth?: any;
    isSignIn?: boolean;
    signInUser?: Function;
    signOutUser?: Function;
    step?: any;
    me?: any;
    setMe?: Function;
    getMe?: Function;
    signInWithGoogle: Function;
}

export interface NavContext {
    setNavActiveTab?: Function;
    navActiveTab?: string;
}

export interface Schedule {
    id: string;
    date?: string;
    name?: string;
    event?: any;
    market?: string;
    createDate?: string;
    status?: string;
    is_popup_event?: boolean;
    comment?: string;
    utc_start?: string;
    utc_end?: string;
    time_start?: string;
    time_end?: string;
    hidden_to_public?: boolean;
    time_arrival?: string;
    food_time?: string;
    customerFee?: number;
    number_of_trucks?: number;
    partnerFee?: number;
    weather?: any;
    distance?: number;
    food_trucks?: any[];
    time_end_format?: string;
    time_start_format?: string;
    food_time_display?: string;
    tag?: string;
    schedulePartners: SchedulePartner[];
    orderCount?: number;
    buildingName?: string;
    partners?: string;
}

export interface Partner {
    id: string;
    name?: string;
    display_name?: string;
    photo?: string;
    photo_thumbnail?: string;
    createDate?: string;
    description?: string;
    status?: string;
    market?: string;
    latitude?: number;
    longitude?: number;
    seo_title?: string;
    seo_description?: string;
    discount?: number;
    short_description?: string;
    contact_name?: string;
    phone?: string;
    phone_1?: string;
    phone_2?: string;
    phone_3?: string;
    phone_4?: string;
    phone_5?: string;
    website?: string;
    food?: any[];
    food_category?: [];
    primary_food_category?: string;
    food_preference_dislike_building_ids?: string;
    building_ban_ids?: [];
    days_of_week?: string[];
    radius?: number;
    address?: string;
    auto_accept?: boolean;
    instagram?: string;
    twitter?: string;
    facebook?: string;
    created_at?: string;
    email?: string;
    is_files_moderated?: boolean;
    is_test?: boolean;
    take_orders_in_lobby?: boolean;
    set_up_in_lobby?: boolean;
    take_orders_online?: boolean;
    take_orders_url?: string;
    avg_score?: number;
    avg_ticket_number?: number;
    avg_sales?: string;
    type_truck?: any;
    truck_preferences?: string;
    status_comment?: string;
    sf_account_id?: string;
    popup_water_needed?: boolean;
    popup_electricity_needed?: boolean;
    popup_tables_is_able_to_provide?: boolean;
    popup_tablecloth_is_able_to_provide?: boolean;
    popup_food_preparation?: string;
    merchantId?: string;
    menus?: Menu[];
    menu?: any;
    merchant?: any;
    partnerFee?: number;
    orderNum: number;
    schedules: SchedulePartner[];
    selectedMenu: string;
}

export interface Order {
    id: string;
    orderType: OrderType;
    buildingName: string;
    buildingPickupArea: string;
    createDate: string;
    createDateFormatted: string;
    items: OrderItem[];
    status: OrderStatus;
    userId: string;
    partnerId: string;
    partnerName?: string;
    customerFirstName: string;
    customerLastName: string;
    customerEmail: string;
    customerPhoneNumber?: string;
    scheduleId: string;
    total: string;
    pickupTime?: string
    isTest?: boolean;
    orderNum: number;
    orderNumFormatted: string;
    numOrders?: number;
    numOrdersPrepared?: number;
    numPendingOrders?: number;
    estimateWaitMins?: number;
    discounts?: Array<any>;
    line_items: any;
    total_money: any;
}

export interface OrderItem {
    id?: string;
    name?: string;
    quantity?: string;
    variation_name?: string;
    base_price_money: string;
    total_money: string;
    modifiers?: OrderItemModifier[]
}

export enum OrderStatus {
    PROPOSED = 'PROPOSED',
    RESERVED = 'RESERVED',
    PREPARED = 'PREPARED',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED',
    FAILED = 'FAILED'
}

export interface OrderItemModifier {
    id?: string;
    name?: string;
    base_price_money: string;
    total_price_money: string;
}

export enum MenuStatus {
    APPROVED = 'APPROVED',
    PENDING_REVIEW = 'PENDING_REVIEW',
    DISABLED = 'DISABLED',
    ADJUSTMENT_NEEDED = 'ADJUSTMENT_NEEDED',
}

export interface Menu {
    id: string;
    name: string;
    status: MenuStatus;
    partnerId: string;
    updated_at?: string;
    categories?: MenuCategory[];
    taxes?: any[];
}

export interface MenuCategory {
    id: string;
    name: string;
    items?: MenuItem[];
    is_deleted?: boolean;
    present_at_all_locations?: boolean;
}

export interface MenuItem {
    id?: string;
    name?: string;
    description?: string;
    is_deleted?: boolean;
    visibility?: string;
    product_type?: string;
    category_id?: string;
    image_id?: string;
    image_url?: string;
    category?: string;
    menus?: string[];
    menuTypes?: string[]; // TODO remove this field later
    present_at_all_locations?: boolean;
    modifiers?: MenuModifierList[];
    variations?: MenuVariation[];
    isApproved?: boolean;
    reviewNotes?: string;
    price?: string;
}

export interface MenuModifierList {
    id?: string;
    name?: string;
    selection_type?: string;
    present_at_all_locations?: boolean;
    min_selected_modifiers?: number;
    max_selected_modifiers?: number;
    enabled?: boolean;
    modifiers?: MenuModifier[];
}

export interface MenuModifier {
    id?: string;
    present_at_all_locations?: boolean;
    is_deleted?: boolean;
    name?: string;
    on_by_default?: boolean;
    ordinal?: number;
    modifier_list_id?: string;
    price_money?: { amount: number, currency: string };
}

export interface MenuVariation {
    type?: string;
    id?: string;
    updated_at?: string;
    version?: number;
    is_deleted?: boolean;
    present_at_all_locations?: boolean;
    present_at_location_ids?: string[];
    absent_at_location_ids?: string[];
    item_variation_data?: {
        item_id?: string;
        name?: string;
        sku?: string;
        ordinal?: number;
        pricing_type?: string;
        price_money?: {
            amount?: number;
            currency?: string;
        }
    };
}

export interface User {
    id?: string
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    phoneNumber?: string;
    partnerId?: string;
    buildingId?: string;
    role: any;
    partners?: any[];
    buildings?: any[]
    customers?: any[];
    signInLinkEmail?: string;
    customer?: any;
}

export enum CouponType {
    PERCENTAGE = 'PERCENTAGE',
    FIXED = 'FIXED'
}

export enum CouponDuration {
    FOREVER = 'FOREVER',
    TIME_LIMITED = 'TIME_LIMITED',
    NUMBER_USES = 'NUMBER_USES'
}

export class CouponEligibility {
    applyToSchedule?: string;

    firstOrderOnly?: boolean;

    specificUser?: string;

    limitNumberOfRedemptionPerUser?: number;

    minimumOrderValue?: number;
}

export interface Coupon {
    userId: string;
    scheduleId?: string
    code: string;
    couponType: CouponType;
    startDate: string;
    duration: CouponDuration;
    expiredDate: string;
    eligibility?: CouponEligibility;
    value?: number;
}

export enum SelectionType {
    SINGLE = 'SINGLE',
    MULTIPLE = 'MULTIPLE',
}
