/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props, no-unused-vars, no-param-reassign */
import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import {
  Table, Button, DatePicker, message, Input, Space,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { CSVDownload } from 'react-csv';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { Link } from 'react-router-dom';
import * as apiClient from '../../common/api-client';
import { Schedule, Partner, NavContext } from '../../common/types';
import { navContext as _navContext } from '../../contexts/nav/navContext';

const { RangePicker } = DatePicker;

const ScheduleList = (props: { partner?: Partner }) => {
  const navContext: NavContext = useContext(_navContext);
  const setNavActiveTab = navContext.setNavActiveTab!;
  const [redirect, setRedirect] = useState<string>('');
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [downloadCSV, setDownloadCSV] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [reportCSVDownload, setReportCSVDownload] = useState<any>();
  const dateString = moment().format('YYYY-MM-DD');
  const fromDate = useRef<string>(dateString);
  const toDate = useRef<string>(dateString);

  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<any>();

  const allSchedules = useRef<Schedule[]>([]);

  const { partner } = props;

  const loadSchedules = async (tableFilters?: any) => {
    setIsFetching(true);
    let data: any = [];
    const filters = tableFilters;

    Object.keys(tableFilters).forEach((i) => {
      if (!filters[i]) {
        delete filters[i];
      } else {
        filters[i] = (Array.isArray(filters[i]) ? filters[i][0] : filters[i]);
      }
    });

    if (partner) {
      data = partner.schedules || [];
    } else {
      data = await apiClient.listAllSchedules();
    }
    allSchedules.current = data;

    const date: Moment = moment();

    let filteredSchedules: Schedule[] = allSchedules.current;

    if (filters.date) {
      filters.datefrom = moment(filters.date.datefrom).startOf('day');
      filters.dateto = moment(filters.date.dateto).endOf('day');
      delete filters.date;

      fromDate.current = filters.datefrom.format('YYYY-MM-DD');
      toDate.current = filters.dateto.format('YYYY-MM-DD');

      filteredSchedules = allSchedules.current
        .filter((schedule: Schedule, i) => {
          schedule.buildingName = schedule.event?.buildingName;
          schedule.partners = schedule.food_trucks?.map((f) => f.name).join();
          return moment(schedule.date)
            .isBetween(filters.datefrom, filters.dateto, 'day', '[]');
        });
    } else {
      filteredSchedules = allSchedules.current
        .filter((schedule: Schedule) => {
          schedule.buildingName = schedule.event?.buildingName;
          schedule.partners = schedule.food_trucks?.map((f) => f.name).join();
          return moment(schedule.date)
            .isBetween(
              moment(date),
              moment(date).add(7, 'days'),
              'day',
              '[]',
            );
        });
    }
    filteredSchedules.sort((a: any, b: any) => +new Date(a.date) - +new Date(b.date));
    setSchedules(filteredSchedules);
    setIsFetching(false);
  };

  useEffect(() => { loadSchedules({}); }, []);

  const handleTableChange = (pagination, tableFilters) => {
    loadSchedules(tableFilters);
  };

  const statusMap = {
    open: 'Open', in_progress: 'In Progress', completed: 'Completed', closed: 'Closed', canceled: 'Canceled',
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      // eslint-disable-next-line react/jsx-indent
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
      // eslint-disable-next-line indent
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#d14242' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text, s) => {
      if (dataIndex === 'buildingName') {
        return `${s.buildingName}`;
      }

      if (dataIndex === 'partners') {
        return (
          s.food_trucks && s.food_trucks.length > 0
          && (
            <div>
              {
                s.food_trucks.map((f) => (
                  <div key={f.id}>
                    <Link to={`/partners/${f.id}`} onClick={() => setNavActiveTab('partners')}>
                      {f.name}
                    </Link>
                  </div>
                ))
              }
            </div>
          )
        );
      }

      return (searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (text));
    },
  });

  const columns = [
    {
      title: 'Date',
      key: 'date',
      filterIcon: () => <FilterFilled style={{ color: '#d14242' }} />,
      filterDropdown: (
        {
          setSelectedKeys,
          confirm,
        }: {
          setSelectedKeys: any;
          confirm: any;
        },
      ) => {
        const date: any = moment();

        return (
          <div style={{ padding: 8 }}>
            <div>
              <RangePicker
                allowClear={false}
                onChange={(e) => e && setSelectedKeys([{ datefrom: e[0], dateto: e[1] }])}
                defaultValue={[moment(date) as any, moment(date).add(7, 'days') as any]}
                ranges={{
                  Today: [moment(date) as any, moment(date) as any],
                  'This Week': [moment(date.startOf('week')) as any, moment(date.endOf('week')) as any],
                  'Two Weeks': [moment(date.subtract(7, 'days')) as any, moment(date.add(7, 'days'))] as any,
                  'This Month': [moment(date.startOf('month')) as any, moment(date.endOf('month'))] as any,
                  'This Year': [moment(date.startOf('year')) as any, moment(date.endOf('year'))] as any,
                }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
            </div>
          </div>
        );
      },
      onFilter: (value, record) => record,
      render: (s) => s.utc_start && s.utc_end && (
        <div>
          <Link to={`/schedules/${s.id}`}>
            <div>
              {moment(s.utc_start).format('dddd MMM Do')}
            </div>
            <div>
              {`${moment(s.utc_start).format('h:mm a')} - ${moment(s.utc_end).format('h:mm a')}`}
            </div>
          </Link>
        </div>
      ),
    },
    {
      title: 'Building',
      key: 'building',
      ...getColumnSearchProps('buildingName'),
    },
    {
      title: 'Event',
      key: 'event',
      render: (s) => s.event && s.event.name,
    },
    {
      title: 'Partners',
      key: 'partners',
      ...getColumnSearchProps('partners'),
    },
    {
      title: 'Status',
      key: 'status',
      render: (s) => (statusMap[s.status]),
      filters: Object.keys(statusMap).map((key, index) => ({
        text: statusMap[key],
        value: key,
      })),
      onFilter: (value, record) => record.status === value,
    },
  ];

  const renderCSVDownload = () => {
    if (downloadCSV) {
      return <CSVDownload data={schedules} target="_blank" />;
    }
    if (reportCSVDownload) {
      return (
        <CSVDownload
          data={[reportCSVDownload]}
          target="_blank"
        // filename={`SCHEDULE-${schedule.id}-Report.csv`}
        />
      );
    }
    return null;
  };

  const flattenObject = (obj, prefix = '') => Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (obj[k] && typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

  const generateReport = async () => {
    setGeneratingReport(true);
    try {
      const markets: string[] = [];

      schedules.forEach((s) => {
        if (s.market && !markets.includes(s.market)) {
          markets.push(s.market);
        }
      });

      const response = await apiClient.getDateRangeReporting(
        fromDate.current, toDate.current, JSON.stringify(markets),
      );

      const flatReport = flattenObject(response);

      setReportCSVDownload(flatReport);

      setGeneratingReport(false);
    } catch (error) {
      message.error('Error generating report!');
      setGeneratingReport(false);
    }
  };

  return (
    <>
      {renderCSVDownload()}
      <div className="index-menu-tab-input list-actions">
        {!isFetching && (
          <div style={{ display: 'flex', marginRight: '1rem' }}>
            <Button
              onClick={() => {
                setDownloadCSV(true);
                setTimeout(() => setDownloadCSV(false), 1000);
              }}
              type="primary"
            >
              Export
            </Button>
            <Button
              onClick={() => generateReport()}
              loading={generatingReport}
              type="primary"
            >
              Generate Report
            </Button>
          </div>
        )}
      </div>
      <Table
        onRow={(record: Schedule) => ({
          onClick: () => setRedirect(record.id),
        })}
        dataSource={schedules}
        // @ts-ignore
        columns={columns}
        rowKey="id"
        rowClassName="table-row"
        loading={isFetching}
        style={{ margin: '1rem' }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default ScheduleList;
