/* eslint-disable react/require-default-props, no-unused-vars, react/destructuring-assignment */
import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import {
  Table, message, DatePicker, Button, Input, Space, Modal,
} from 'antd';
import uuidv4 from 'uuid/dist/v4';
import Highlighter from 'react-highlight-words';
import { CSVDownload } from 'react-csv';
import { SearchOutlined, FilterFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { Redirect, Link } from 'react-router-dom';
import * as apiClient from '../../common/api-client';
import {
  Order, Partner, NavContext, Schedule,
} from '../../common/types';
import { navContext as _navContext } from '../../contexts/nav/navContext';
import OrderDetail from './OrderDetail';

const { RangePicker } = DatePicker;
const confirmModal = Modal.confirm;

const OrderList = (props: { partner?: Partner, schedule?: Schedule }) => {
  const [redirect, setRedirect] = useState<string>('');
  const [orders, setOrders] = useState<Order[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const navContext: NavContext = useContext(_navContext);
  const setNavActiveTab = navContext.setNavActiveTab!;
  const [orderDetail, setOrderDetail] = useState<{ orderNumFormatted: number, order: Order }>();
  const [orderModalVisible, setOrderModalVisible] = useState<boolean>(false);
  const [gettingSquareOrders, setGettingSquareOrders] = useState<boolean>(false);
  const [gettingSquarePayments, setGettingSquarePayments] = useState<boolean>(false);
  const [downloadCSV, setDownloadCSV] = useState<boolean>(false);
  const [squareCSVDownload, setSquareCSVDownload] = useState<any>();
  const [refundsLoading, setRefundsLoading] = useState<any>();

  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');

  const searchInput = useRef<any>();
  const foodTruckId = useRef<any>();
  const selectedScheduleId = useRef<any>();

  const allOrders = useRef<Order[]>([]);

  const { partner, schedule } = props;

  const loadOrders = async (scheduleId?: string, partnerId?: string, tableFilters?) => {
    try {
      let data: Order[] = [];
      const filters = tableFilters;

      Object.keys(tableFilters).forEach((i) => {
        if (!filters[i]) {
          delete filters[i];
        } else {
          filters[i] = (Array.isArray(filters[i]) ? filters[i][0] : filters[i]);
        }
      });

      if (scheduleId && partnerId) {
        data = await apiClient.listSchedulePartnerOrders(scheduleId, partnerId);
      } else if (!schedule && !partnerId) {
        data = await apiClient.listAllOrders();
      }

      allOrders.current = data;

      const date: Moment = moment();

      let filteredOrders: Order[] = allOrders.current;

      if (filters.pickup) {
        filters.datefrom = moment(filters.pickup.datefrom).startOf('day');
        filters.dateto = moment(filters.pickup.dateto).endOf('day');
        delete filters.pickup;

        filteredOrders = allOrders.current
          .filter((order: Order) => moment(order.pickupTime)
            .isBetween(filters.datefrom, filters.dateto, 'day', '[]'));
      } else {
        filteredOrders = allOrders.current
          .filter((order: Order) => moment(order.pickupTime)
            .isBetween(
              moment(date.startOf('year')),
              moment(date.endOf('year')),
              'day',
              '[]',
            ));
      }

      setOrders(filteredOrders);

      setIsFetching(false);
    } catch (error) {
      message.error(error.message);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    let scheduleId = schedule?.id;
    let partnerId = partner?.id;
    let selectedSchedule;

    if (partner) {
      scheduleId = partner?.schedules[0].schedule;
      selectedSchedule = partner?.schedules.find((s) => s.schedule === scheduleId);
    }

    if (schedule) {
      const partners = schedule.food_trucks && schedule.food_trucks.length
        ? schedule.food_trucks.filter((f) => f.status === 'confirmed_offer') : [];

      partnerId = (partners && partners.length)
        ? partners[0].food_truck : null;
      selectedSchedule = schedule;
    }

    if (selectedSchedule) {
      selectedScheduleId.current = partner ? selectedSchedule?.schedule : selectedSchedule?.id;
    }

    foodTruckId.current = partnerId;

    loadOrders(scheduleId, partnerId, {});
  }, []);

  const statusMap = {
    PROPOSED: 'Placed',
    CANCELED: 'Cancelled',
    RESERVED: 'In Progress',
    PREPARED: 'Prepared',
    COMPLETED: 'Completed',
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      // eslint-disable-next-line react/jsx-indent
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={dataIndex === 'customerLastName' ? 'Search first name' : `Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
      // eslint-disable-next-line indent
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text, o) => {
      if (dataIndex === 'customerLastName') {
        return `${text.customerFirstName || ''} ${text.customerLastName || ''}`;
      }

      if (dataIndex === 'orderNum') {
        return `${text.orderNum || ''}`;
      }

      return (searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (text));
    },
  });

  const columns: any[] = [
    {
      title: 'Placed',
      key: 'placed',
      render: (o) => (`${o.createDate && moment(o.createDate).format('M/D  h:mm a')}`),
    },
    {
      title: 'Pickup',
      key: 'pickup',
      filterIcon: () => <FilterFilled style={{ color: (!schedule && !partner) ? '#d14242' : 'auto' }} />,
      filterDropdown: (
        {
          setSelectedKeys,
          confirm,
        }: {
          setSelectedKeys: Function;
          confirm: Function;
        },
      ) => {
        const date: any = moment();

        return (
          <div style={{ padding: 8 }}>
            <div>
              <RangePicker
                allowClear={false}
                onChange={(e) => e && setSelectedKeys([{ datefrom: e[0], dateto: e[1] }])}
                // defaultValue={[date.subtract(7, 'days'), date.add(7, 'days')]}
                defaultValue={[moment(date.startOf('year')) as any, moment(date.endOf('year')) as any]}
                ranges={{
                  Today: [moment(date) as any, moment(date) as any],
                  'This Week': [moment(date.startOf('week')) as any, moment(date.endOf('week')) as any],
                  'Two Weeks': [moment(date.subtract(7, 'days')) as any, moment(date.add(7, 'days'))] as any,
                  'This Month': [moment(date.startOf('month')) as any, moment(date.endOf('month'))] as any,
                  'This Year': [moment(date.startOf('year')) as any, moment(date.endOf('year'))] as any,
                }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
            </div>
          </div>
        );
      },
      onFilter: (value, record) => record,
      render: (o) => (`${o.pickupTime && moment(o.pickupTime).format('M/D  h:mm a')} ${o.note ? ` ( spot ${o.note.split(' ').slice(2).join(' ')} )` : ''}`),
    },
    {
      title: 'Order',
      key: 'order',
      ...getColumnSearchProps('orderNum'),
    },
    {
      title: 'Customer',
      key: 'customer',
      ...getColumnSearchProps('customerLastName'),
    },
    {
      title: 'Platform',
      key: 'platform',
      render: (o) => (<span style={{ textTransform: 'capitalize' }}>{o.platform || ''}</span>),
    },
    {
      title: 'Payment',
      key: 'payment',
      render: ({ order, paymentId, payment }) => (
        paymentId ? (payment.total_money && `$${payment.total_money.amount / 100} ${payment.card_details ? `${payment.card_details?.card.card_brand} ${payment.card_details?.card.last_4}` : `${payment.source_type}`}`) : `$${order.net_amounts.total_money.amount / 100}`
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (o) => (o.refund ? 'Refunded' : statusMap[o.status]),
      filters: Object.keys(statusMap).map((key, index) => ({
        text: statusMap[key],
        value: key,
      })),
      onFilter: (value, record) => record.status === value,
    },
  ];

  const statuses = ['confirmed_offer', 'declined_truck', 'canceled_offer'];

  if (!partner) {
    const partners = schedule?.food_trucks && schedule.food_trucks.length
      ? schedule.food_trucks.filter((f) => statuses.includes(f.status)) : [];
    columns.unshift({
      title: 'Partner',
      key: 'partner',
      filters: partners.map((f) => ({
        text: f.name || '',
        value: f.food_truck.toString() || '',
      })),
      defaultFilteredValue: partners.length
        ? [(partners.find((p) => p.status === statuses[0]))?.food_truck.toString() || ''] : null,
      filterMultiple: false,
      filterDropdownVisible: (schedule || false),
      render: (o) => (
        <Link to={`/partners/${o.partnerId}`} onClick={() => setNavActiveTab('partners')}>
          {`${o.partnerName || ''}`}
        </Link>
      ),
    });
  }

  if (!schedule) {
    columns.unshift({
      title: 'Schedule',
      key: 'schedule',
      filters: (partner && partner.schedules)
        ? partner.schedules?.filter((f) => statuses.includes(f.status!))
          .map((s) => ({
            text: (
              <span>
                <span style={{ marginRight: '10px' }}>
                  {moment(s.utc_start).format('MMM Do')}
                </span>
                <span>{`${s.buildingName || ''}`}</span>
              </span>
            ),
            value: s.schedule || '',
          })) : [],
      defaultFilteredValue: partner ? [partner.schedules[0].schedule || ''] : null,
      filterMultiple: false,
      filterDropdownVisible: (partner || false),
      render: (o) => (
        <Link to={o.scheduleId ? `/schedules/${o.scheduleId}` : ''} onClick={() => (o.scheduleId && setNavActiveTab('schedules'))}>
          <span style={{ marginRight: '10px' }}>
            {o.pickupTime && `${moment(o.pickupTime).format('MMM Do')}`}
          </span>
          <span>{`${o.buildingName || ''}`}</span>
        </Link>
      ),
    });
  }

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={`/ orders / ${redirect}`} />;
    }
    return null;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const scheduleId = schedule?.id
      || (filters.schedule ? filters.schedule[0] : null);

    const partnerId = partner?.id
      || (filters.partner ? filters.partner[0] : null);

    let selectedSchedule;
    if (partner) {
      selectedSchedule = partner?.schedules.find((s) => s.schedule === scheduleId);
    } else {
      selectedSchedule = schedule;
    }
    if (selectedSchedule) {
      selectedScheduleId.current = partner ? selectedSchedule?.schedule : selectedSchedule.id;
    }
    setIsFetching(true);
    loadOrders(scheduleId, partnerId, filters);
  };

  const flattenObject = (obj, prefix = '') => Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (obj[k] && typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

  const downloadJSON = (json) => {
    const dataStr = `data: text / json; charset = utf - 8, ${encodeURIComponent(JSON.stringify(json))} `;
    const dlAnchorElem = document.getElementById('downloadAnchorElem');
    dlAnchorElem!.setAttribute('href', dataStr);
    dlAnchorElem!.setAttribute('download', `PARTNER - ${foodTruckId.current} -${selectedScheduleId.current} -Orders.json`);
    dlAnchorElem!.click();
  };

  const getSquareOrders = async () => {
    setGettingSquareOrders(true);
    try {
      const response = await apiClient.listPartnerSquareOrders(
        foodTruckId.current, selectedScheduleId.current,
      );

      const flatOrders = response.map((j) => flattenObject(j));

      setSquareCSVDownload(flatOrders);

      // downloadJSON(response);

      setGettingSquareOrders(false);
    } catch (error) {
      message.error('Error getting orders from square!');
      setGettingSquareOrders(false);
    }
  };

  const getSquarePayments = async () => {
    setGettingSquarePayments(true);
    try {
      const response = await apiClient.listPartnerSquarePayments(
        foodTruckId.current, selectedScheduleId.current, selectedScheduleId.current,
      );

      const flatPayments = response.payments.map((j) => flattenObject(j));

      setSquareCSVDownload(flatPayments);

      // downloadJSON(response);

      setGettingSquarePayments(false);
    } catch (error) {
      message.error('Error getting payments from square!');
      setGettingSquarePayments(false);
    }
  };

  const renderCSVDownload = () => {
    if (downloadCSV) {
      // downloadJSON(orders);
      return (
        <CSVDownload
          data={orders.map((j) => flattenObject(j))}
          target="_blank"
          filename={`PARTNER - ${foodTruckId.current} -${selectedScheduleId.current} -Orders.csv`}
        />
      );
    }
    if (squareCSVDownload) {
      return (
        <CSVDownload
          data={squareCSVDownload}
          target="_blank"
          filename={`PARTNER - ${foodTruckId.current} -${selectedScheduleId.current} -Orders.csv`}
        />
      );
    }
    return null;
  };

  const refundAllOrders = async () => {
    confirmModal({
      title: 'Are you sure you want to refund all orders?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        setRefundsLoading(true);
        for (let i = 0; i < orders.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          await apiClient.refundOrder(
            orders[i].partnerId,
            orders[i].scheduleId,
            orders[i].id,
            {
              reason: 'Order Refund',
              key: uuidv4().replace(/-/g, ''),
            },
          );
        }
        setRefundsLoading(false);
      },
      onCancel() {
      },
    });
  };

  return (
    <>
      {renderRedirect()}
      {renderCSVDownload()}
      <a
        href="/"
        id="downloadAnchorElem"
        style={{ display: 'none' }}
      >
        csv
      </a>
      {foodTruckId.current && (
        <div className="index-menu-tab-input list-actions">
          <Button
            onClick={() => {
              setDownloadCSV(true);
              setTimeout(() => setDownloadCSV(false), 1000);
            }}
            type="primary"
          >
            Export Curbside Orders
          </Button>
          <Button
            onClick={() => getSquareOrders()}
            loading={gettingSquareOrders}
          >
            Export Square Orders
          </Button>
          {/* <Button
            onClick={() => getSquarePayments()}
            loading={gettingSquarePayments}
          >
            Export Square Payments
          </Button> */}
        </div>
      )}
      <Table
        onRow={(record: any) => ({
          onClick: () => {
            setOrderDetail(record);
            setOrderModalVisible(true);
          },
        })}
        dataSource={orders}
        columns={columns}
        rowKey="id"
        rowClassName="table-row"
        loading={isFetching}
        style={{ margin: '1rem' }}
        onChange={handleTableChange}
      />
      {!isFetching && (partner || schedule) && (
        <Button
          loading={refundsLoading}
          onClick={() => refundAllOrders()}
          style={{ marginBottom: '1rem' }}
        >
          Refund All Orders
        </Button>
      )}
      {orderDetail
        && (
          <OrderDetail
            visible={orderModalVisible}
            onCancel={() => setOrderModalVisible(false)}
            detail={orderDetail}
          />
        )}
    </>
  );
};

export default OrderList;
