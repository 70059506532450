/* eslint-disable */
import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { navContext as NavContext } from './navContext';
import navReducer from './navReducer';
import {
  SET_NAV_ACTIVE_TAB,
} from '../../common/dispatch';
const NavState = (props: { children: any }) => {
  const initialState: any = {};

  const [state, dispatch] = useReducer(navReducer, initialState);

  const setNavActiveTab = async (tab: string) => {
    dispatch({
      type: SET_NAV_ACTIVE_TAB,
      payload: tab,
    });
  };

  const { children } = props;

  return (
    <NavContext.Provider
      value={{
        setNavActiveTab,
        navActiveTab: state.navActiveTab,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export default NavState;

NavState.propTypes = {
  children: PropTypes.shape({}),
};

NavState.defaultProps = {
  children: {},
};
