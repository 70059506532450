/* eslint-disable react/require-default-props, no-unused-vars */
import React, {
  useEffect, useState, useRef,
} from 'react';
import {
  Table, message, Button, Input, Space,
} from 'antd';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { CSVDownload } from 'react-csv';
import * as apiClient from '../../common/api-client';
import { User, Partner } from '../../common/types';
import { navContext as _navContext } from '../../contexts/nav/navContext';
import PartnerUserForm from '../partners/PartnerUser';

const UserList = (props: { partner?: Partner; }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [downloadCSV, setDownloadCSV] = useState<boolean>(false);

  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');

  const searchInput = useRef<any>();

  const { partner } = props;

  const loadUsers = async () => {
    try {
      let data: User[];
      if (partner) {
        data = await apiClient.listUsersForPartner(partner.id);
      } else {
        data = await apiClient.listAllUsers();
      }
      setUsers(data);
      setIsFetching(false);
    } catch (error) {
      message.error(error.message);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      // eslint-disable-next-line react/jsx-indent
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder="Search first name"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
      // eslint-disable-next-line indent
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) => `${text.firstName || ''} ${text.lastName || ''}`,
  });

  const columns: any[] = [
    {
      title: 'Name',
      key: 'firstName',
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Role',
      key: 'role',
      render: (u) => `${u.role || ''}`,
      filters: [
        { text: 'Admin', value: 'admin' },
        { text: 'Partner', value: 'partner' },
        { text: 'Customer', value: 'customer' },
      ],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (u) => `${u.phoneNumber || ''}`,
    },
    {
      title: 'Email',
      key: 'email',
      render: (u) => `${u.email || ''}`,
    },
  ];

  const onPartnerUserCreate = async (values) => {
    try {
      setModalLoading(true);
      const user = await apiClient.createPartnerUser(partner?.id!, {
        ...values,
        // Formatting for Firebase
        phoneNumber: `+1${values.phone.replace(/\D/g, '')}`,
      });
      setUsers([...users, user]);
      message.success('Partner user added.');
      setModalLoading(false);
    } catch (error) {
      message.error('Error adding partner user.');
      setModalLoading(false);
    }
    setModalOpen(false);
  };

  const renderCSVDownload = () => {
    if (downloadCSV) {
      return <CSVDownload data={users} target="_blank" />;
    }
    return null;
  };

  return (
    <>
      {renderCSVDownload()}
      <div className="index-menu-tab-input list-actions">
        {partner && !isFetching && (
          <Button onClick={() => setModalOpen(true)} style={{ marginRight: '16px' }}>Add User</Button>
        )}
        {!isFetching && !partner && (
          <Button
            onClick={() => {
              setDownloadCSV(true);
              setTimeout(() => setDownloadCSV(false), 1000);
            }}
            type="primary"
            className="export-button"
          >
            Export
          </Button>
        )}
      </div>
      <Table
        dataSource={users}
        columns={columns}
        rowKey="id"
        rowClassName="table-row"
        loading={isFetching}
        style={{ margin: '1rem' }}
      />
      <PartnerUserForm
        visible={modalOpen}
        onCreate={onPartnerUserCreate}
        onCancel={() => {
          setModalOpen(false);
        }}
        loading={modalLoading}
      />
    </>
  );
};

export default UserList;
