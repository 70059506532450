/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  PageHeader, Row, Col, Descriptions, Input,
  Tabs, Spin, InputNumber, Button, message, Form, Modal,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import { Redirect } from 'react-router-dom';
import { CSVDownload } from 'react-csv';
import * as apiClient from '../../common/api-client';
// eslint-disable-next-line no-unused-vars
import { Schedule } from '../../common/types';

import OrderList from '../orders/OrderList';
import SchedulePartnerList from '../schedulePartners/SchedulePartnerList';

const ScheduleDetail = ({ match }: {
  match?: { params: { scheduleId } },
}) => {
  const [schedule, setSchedule] = useState<Schedule | any>({});
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [customerFeeLoading, setCustomerFeeLoading] = useState<boolean>(false);
  const [orderAheadDaysLoading, setOrderAheadDaysLoading] = useState<boolean>(false);
  const [newCustomerFeeValue, setNewCustomerFeeValue] = useState<number>();
  const [newOrderAheadDaysValue, setNewOrderAheadDaysValue] = useState<number>();
  const [showSMSDialog, setShowSMSDialog] = useState<any>();
  const [smsLoading, setSMSLoading] = useState(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [generatingFullDayReport, setGeneratingFullDayReport] = useState<boolean>(false);
  const [reportCSVDownload, setReportCSVDownload] = useState<any>();

  const [form] = Form.useForm();

  const [back, setBack] = useState(false);
  const { TabPane } = Tabs;

  const loadSchedule = async () => {
    const { scheduleId } = match!.params;
    const data = await apiClient.getSchedule(scheduleId);
    setSchedule(data);
    setIsFetching(false);
  };

  useEffect(() => { loadSchedule(); }, []);

  const detailHeader = () => (
    <PageHeader
      style={{ backgroundColor: '#fff' }}
      onBack={() => setBack(true)}
      title={`${moment(schedule.utc_start).format('MMM Do')} ${moment(schedule.utc_start).format('h:mm a')} @ ${(schedule.event && schedule.event.buildingName) || ''}`}
    />
  );

  const detailBody = () => (
    <Row gutter={16}>
      <Col>
        <Descriptions bordered size="small" column={2} style={{ flex: 1, margin: '0 3rem 2rem 3rem' }}>
          <Descriptions.Item label="Schedule Name">
            {`${schedule.name}`}
          </Descriptions.Item>
          <Descriptions.Item label="Default Customer Fee">
            {schedule.customerFee ? `$${parseFloat((schedule.customerFee / 100).toFixed(2)) || 0}` : 'None'}
          </Descriptions.Item>
          <Descriptions.Item label="Location">
            {`${schedule.event.location}`}
          </Descriptions.Item>
          <Descriptions.Item label="Tag">
            {`${schedule.tag || 'None'}`}
          </Descriptions.Item>
          <Descriptions.Item label="Start">
            {`${moment(schedule.utc_start).format('dddd MMM Do')} ${moment(schedule.utc_start).format('h:mm a')}`}
          </Descriptions.Item>
          <Descriptions.Item label="End">
            {`${moment(schedule.utc_end).format('dddd MMM Do')} ${moment(schedule.utc_end).format('h:mm a')}`}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );

  const onCustomerFeeSet = async () => {
    try {
      setCustomerFeeLoading(true);
      await apiClient.updateSchedule(schedule.id, { customerFee: newCustomerFeeValue! * 100 });
      setCustomerFeeLoading(false);
      message.success('Default Customer Fee updated');
    } catch (error) {
      setCustomerFeeLoading(false);
      message.success('Error updating Customer Fee');
    }
  };

  const onOrderAheadDaysSet = async () => {
    try {
      setOrderAheadDaysLoading(true);
      await apiClient.updateSchedule(schedule.id, { orderAheadDays: newOrderAheadDaysValue! });
      setOrderAheadDaysLoading(false);
      message.success('Default Order Ahead Days updated');
    } catch (error) {
      setOrderAheadDaysLoading(false);
      message.success('Error updating default order ahead days');
    }
  };

  const renderBack = () => {
    if (back) {
      return <Redirect to="/schedules" />;
    }
    return null;
  };

  const sendSMSNotification = async (value) => {
    setSMSLoading(true);
    try {
      await apiClient.sendSMSMessage(schedule.event.buildingId, schedule.id, value.message);
      setSMSLoading(false);
      setShowSMSDialog(false);
      message.success('Messages sent!');
    } catch (error) {
      setSMSLoading(true);
      message.error('There was a problem sending messages');
    }
  };

  const flattenObject = (obj, prefix = '') => Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (obj[k] && typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

  const generateReport = async (isFullDay?: boolean) => {
    if (isFullDay) {
      setGeneratingFullDayReport(true);
    } else {
      setGeneratingReport(true);
    }

    try {
      const response = await apiClient.getScheduleReporting(schedule.id, isFullDay);

      const flatReport = flattenObject(response);

      setReportCSVDownload(flatReport);

      if (isFullDay) {
        setGeneratingFullDayReport(false);
      } else {
        setGeneratingReport(false);
      }
    } catch (error) {
      message.error('Error generating report!');
      if (isFullDay) {
        setGeneratingFullDayReport(false);
      } else {
        setGeneratingReport(false);
      }
    }
  };

  const renderCSVDownload = () => {
    if (reportCSVDownload) {
      return (
        <CSVDownload
          data={[reportCSVDownload]}
          target="_blank"
          filename={`SCHEDULE-${schedule.id}-Report.csv`}
        />
      );
    }
    return null;
  };

  return (
    schedule && !isFetching ? (
      <div style={{ padding: '0rem 1rem' }}>
        {isFetching && <Spin />}
        {renderBack()}
        {detailHeader()}
        {renderCSVDownload()}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {detailBody()}
          <div style={{ marginRight: '3rem', width: '14rem' }}>
            <Button
              style={{ width: '100%' }}
              onClick={() => setShowSMSDialog(true)}
            >
              Send SMS Notifications
            </Button>
            <Button
              style={{ width: '100%', marginTop: '1rem' }}
              onClick={() => generateReport(true)}
              loading={generatingFullDayReport}
            >
              Generate Full Day Report
            </Button>
            <Button
              style={{ width: '100%', marginTop: '1rem' }}
              onClick={() => generateReport()}
              loading={generatingReport}
            >
              Generate Report
            </Button>
          </div>
          <div style={{ marginRight: '55px' }}>
            Default Customer Fee
            <div style={{ display: 'flex', marginBottom: '1rem' }}>
              <InputNumber
                defaultValue={parseFloat((schedule.customerFee / 100).toFixed(2)) || 0}
                min={0}
                step={0.01}
                formatter={(value) => `$${value}`}
                parser={(value) => Number(value!.replace('$', ''))}
                onChange={(value) => {
                  if (value) {
                    setNewCustomerFeeValue(value as number);
                  }
                }}
              />
              <Button
                type="primary"
                style={{ marginLeft: '2px' }}
                onClick={() => onCustomerFeeSet()}
                loading={customerFeeLoading}
                disabled={!newCustomerFeeValue}
              >
                <SaveOutlined />
              </Button>
            </div>
            Order Ahead Days
            <div style={{ display: 'flex' }}>
              <InputNumber
                defaultValue={parseFloat(schedule.orderAheadDays) || 0}
                min={0}
                onChange={(value) => {
                  if (value) {
                    setNewOrderAheadDaysValue(value as number);
                  }
                }}
              />
              <Button
                type="primary"
                style={{ marginLeft: '2px' }}
                onClick={() => onOrderAheadDaysSet()}
                loading={orderAheadDaysLoading}
                disabled={!newOrderAheadDaysValue}
              >
                <SaveOutlined />
              </Button>
            </div>
          </div>
        </div>
        <Tabs>
          <TabPane tab="Partners" key="partners">
            <SchedulePartnerList schedule={schedule} />
          </TabPane>
          <TabPane tab="Orders" key="orders">
            <OrderList schedule={schedule} />
          </TabPane>
        </Tabs>
        {
          showSMSDialog && (
            // @ts-ignore
            <Modal
              visible={showSMSDialog}
              title="Send SMS Notification"
              onCancel={() => setShowSMSDialog(false)}
              okText="Send"
              cancelText="Cancel"
              okButtonProps={
                { loading: smsLoading }
              }
              onOk={() => {
                form
                  .validateFields()
                  .then((values) => {
                    sendSMSNotification(values);
                    form.resetFields();
                  });
              }}

            >
              <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
              >
                <div style={{ display: 'flex' }}>
                  <Form.Item
                    name="message"
                    label="Message"
                    rules={[{ required: true, message: 'Please input a message!' }]}
                    style={{ flex: 1, margin: '0 0.5rem 0 0' }}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </Form>
            </Modal>
          )
        }
      </div>
    ) : (null)
  );
};

export default ScheduleDetail;
