import { SET_NAV_ACTIVE_TAB } from '../../common/dispatch';

export default (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case SET_NAV_ACTIVE_TAB:
      return {
        ...state,
        navActiveTab: action.payload,
      };
    default:
      return state;
  }
};
