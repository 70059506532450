import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import {
  UserOutlined, ShopOutlined, ScheduleOutlined, SolutionOutlined,
  TeamOutlined, WalletOutlined, FileDoneOutlined,
} from '@ant-design/icons';

// eslint-disable-next-line no-unused-vars
import { AuthContext, NavContext } from '../../common/types';
import { authContext as _authContext } from '../../contexts/auth/authContext';
import { navContext as _navContext } from '../../contexts/nav/navContext';

const Nav = () => {
  const authContext: AuthContext = useContext(_authContext);
  const { auth, me } = authContext;
  const signOutUser = authContext.signOutUser!;
  const navContext: NavContext = useContext(_navContext);
  const setNavActiveTab = navContext.setNavActiveTab!;
  const { navActiveTab } = navContext;

  const tabs = ['schedules', 'partners', 'menus', 'orders', 'coupons', 'users'];

  useEffect(() => {
    const path = window.location.pathname;
    let tabSet = false;
    tabs.forEach((i) => {
      if (path.includes(i)) {
        tabSet = true;
        setNavActiveTab(i);
      }
    });
    if (!tabSet) {
      setNavActiveTab(tabs[0]);
    }
  }, []);

  const handleClick = (e: any) => {
    setNavActiveTab(e.key);
  };

  return (
    <div className="nav">
      <Link to="/">
        <img
          className="nav-logo"
          src={`${process.env.PUBLIC_URL}/assets/logo.png`}
          alt="logo"
        />
      </Link>

      {
        me && (
          <Menu
            onClick={handleClick}
            selectedKeys={[navActiveTab!]}
            mode="horizontal"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Menu.Item key="schedules" icon={<ScheduleOutlined />}>
              <Link to="/schedules">
                Schedules
              </Link>
            </Menu.Item>
            <Menu.Item key="partners" icon={<ShopOutlined />}>
              <Link to="/partners">
                Partners
              </Link>
            </Menu.Item>
            <Menu.Item key="menus" icon={<FileDoneOutlined />}>
              <Link to="/menus">
                Menus
              </Link>
            </Menu.Item>
            <Menu.Item key="orders" icon={<SolutionOutlined />}>
              <Link to="/orders">
                Orders
              </Link>
            </Menu.Item>
            <Menu.Item key="coupons" icon={<WalletOutlined />}>
              <Link to="/coupons">
                Coupons
              </Link>
            </Menu.Item>
            <Menu.Item key="users" icon={<TeamOutlined />}>
              <Link to="/users">
                Users
              </Link>
            </Menu.Item>
          </Menu>
        )
      }

      <div className="nav-avatar">
        {
          me
            ? (
              // @ts-ignore
              <Dropdown
                overlay={(
                  <Menu>
                    <Menu.Item key="0">User</Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="1">{`firebase: ${auth.email || 'Anon'}`}</Menu.Item>
                    <Menu.Item key="2">{`dynamo:  ${me.firstName} ${me.lastName}`}</Menu.Item>
                    <Menu.Divider />
                    {auth.isAnonymous ? null : (
                      <Menu.Item key="3" onClick={() => signOutUser()}>Sign Out</Menu.Item>
                    )}
                  </Menu>
                )}
                trigger={['click']}
              >
                <UserOutlined className="user-button" />
              </Dropdown>
            ) : (<></>)
        }
      </div>
    </div>
  );
};

export default Nav;
