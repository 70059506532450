/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props, no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Table, Select, Button, message, InputNumber, Tooltip, Switch, Modal,
} from 'antd';
import { SaveOutlined, CarryOutOutlined, ExpandAltOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as apiClient from '../../common/api-client';
import {
  SchedulePartner, Partner, NavContext, Schedule, MenuCategory, MenuItem, MenuStatus,
} from '../../common/types';
import { navContext as _navContext } from '../../contexts/nav/navContext';

const SchedulePartnerList = (props: { partner?: Partner; schedule?: SchedulePartner }) => {
  const navContext: NavContext = useContext(_navContext);
  const setNavActiveTab = navContext.setNavActiveTab!;
  const [schedulePartners, setSchedulePartners] = useState<SchedulePartner[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [itemDisableLoading, setItemDisableLoading] = useState<{ itemId?: boolean; }>({});
  const [editingMenuSP, setEditingMenuSP] = useState<any>();

  const { partner, schedule } = props;

  const loadSchedules = async () => {
    const statuses = ['confirmed_offer', 'declined_truck', 'canceled_offer'];

    let data: SchedulePartner[] = [];

    if (partner) {
      data = partner.schedules
        .filter((s) => statuses.includes(s.status!))
        .map((s, index) => ({ ...s, index })) || [];
      const response = await apiClient.listMenus(partner.id);
      partner.menus = response;
    } else if (schedule && schedule.food_trucks) {
      const partners = schedule.food_trucks
        .filter((s) => statuses.includes(s.status!));

      for (let i = 0; i < partners.length; i += 1) {
        partners[i].index = i;
        // eslint-disable-next-line no-await-in-loop
        partners[i].menus = await apiClient
          .listMenus(partners[i].food_truck);
      }
      data = partners;
    }
    setSchedulePartners(data);
    setIsFetching(false);
  };

  useEffect(() => { loadSchedules(); }, []);

  const onPropChange = (
    value: string | number,
    schedulePartner: SchedulePartner,
    index: number,
    prop: string,
  ) => {
    let update = value;

    if (typeof value === 'number') {
      switch (prop) {
        case 'customerFee': update = value * 100; break;
        case 'partnerFee': update = value / 100; break;
        default: update = value; break;
      }
    }

    setSchedulePartners(schedulePartners
      .map((a, i) => (
        i === index ? {
          ...a,
          [prop]: update,
          [`${prop}SaveEnabled`]: value !== schedulePartner[prop] || value === 0,
        } : a)));
  };

  const onPropSet = async (schedulePartner: SchedulePartner, index: number, prop: string) => {
    setSchedulePartners(schedulePartners
      .map((a, i) => (i === index ? { ...a, [`${prop}SaveLoading`]: true } : a)));

    if (prop === 'selectedMenu') {
      // eslint-disable-next-line no-param-reassign
      schedulePartner.menuApproved = moment().format();
      await apiClient.setSchedulePartnerProp(schedulePartner, 'menuApproved');
    }
    try {
      await apiClient.setSchedulePartnerProp(schedulePartner, prop);

      message.success(`${prop} updated`);
    } catch (error) {
      message.error('Request failed');
    }
    setSchedulePartners(schedulePartners
      .map((a, i) => (i === index ? {
        ...a,
        [`${prop}SaveLoading`]: false,
        [`${prop}SaveEnabled`]: false,
      } : a)));
  };

  const onClickCheckInOut = async (schedulePartner: SchedulePartner, prop: string) => {
    const body: any = { [prop]: moment().format() };

    if (prop === 'partnerCheckIn') {
      body.partnerCheckOut = null;
    } else if (prop === 'partnerCheckOut') {
      body.partnerCheckIn = null;
    }

    try {
      await apiClient.checkInOutForEvent(
        schedulePartner.food_truck!,
        schedulePartner.schedule!,
        body,
      );

      const updatedSchedulePartner = {
        ...schedulePartner,
        ...body,
      };

      const updatedSchedulePartners = schedulePartners.map((sp) => (
        sp.id === schedulePartner.id ? {
          ...schedulePartner,
          ...body,
        } : sp));

      setSchedulePartners(updatedSchedulePartners);

      message.success(`${prop} successfull!`);
    } catch (err) {
      message.error(`${prop} error!`);
    }
  };

  const toggleItemDisabled = async (
    catIndex: number,
    itemIndex: number,
    action: boolean,
    itemId: string,
  ) => {
    try {
      setItemDisableLoading({ ...itemDisableLoading, [itemId]: true });
      const updatedCategories = editingMenuSP.menu.categories
        .map((cat: MenuCategory, i: number) => (
          i === catIndex ? ({
            ...cat,
            items: editingMenuSP.menu.categories[catIndex].items
              .map((item: MenuItem, j: number) => (j === itemIndex ? ({
                ...item,
                isDisabled: !action,
              }) : item)),
          }) : cat
        ));

      await apiClient.updateSchedulePartnerMenu(
        editingMenuSP,
        { [action ? 'enabledItem' : 'disabledItem']: editingMenuSP.menu.categories[catIndex].items[itemIndex].id },
      );

      const updatedSchedulePartner = {
        ...editingMenuSP,
        menu: {
          ...editingMenuSP?.menu,
          categories: updatedCategories,
        },
      };

      setEditingMenuSP(updatedSchedulePartner);

      const updatedSchedulePartners = schedulePartners.map((sp) => (
        sp.id === editingMenuSP.id ? updatedSchedulePartner : sp));

      setSchedulePartners(updatedSchedulePartners);

      message.success(`Item has been successfully ${action ? 'enabled' : 'disabled'}!`);
      setItemDisableLoading({ ...itemDisableLoading, [itemId]: false });
    } catch (err) {
      message.error(`There was a problem ${action ? 'enabling' : 'disabling'} this item!`);
      setItemDisableLoading({ ...itemDisableLoading, [itemId]: false });
    }
  };

  const openMenuDialog = async (schedulePartner) => {
    const sp = await apiClient.getSchedulePartner(
      schedulePartner.schedule, schedulePartner.food_truck,
    );
    setEditingMenuSP(sp);
  };

  const columns = [
    {
      title: 'Status',
      key: 'status',
      render: (s) => ({
        declined_truck: 'Declined',
        confirmed_offer: 'Confirmed',
        canceled_offer: 'Canceled',
      }[s.status]),
    },
    {
      title: 'Menu',
      key: 'menu',
      render: (s) => {
        let menus;
        if (partner) {
          menus = partner.menus || [];
        } else if (schedule) {
          menus = s.menus || [];
        }
        return (
          <div style={{ display: 'flex' }}>
            <Select
              style={{ width: '100%' }}
              defaultValue={s.selectedMenu || ''}
              onChange={(value) => onPropChange(value, s, s.index, 'selectedMenu')}
            >
              <Select.Option value="" key="">No Menu</Select.Option>
              {menus.filter((m) => m.status === MenuStatus.APPROVED).map((m) => (
                <Select.Option value={m.id} key={m.id}>{m.name}</Select.Option>
              ))}
            </Select>
            <Tooltip title="Save">
              <Button
                type="primary"
                style={{ marginLeft: '2px' }}
                onClick={() => onPropSet(s, s.index, 'selectedMenu')}
                disabled={(schedulePartners[s.index]
                  && !schedulePartners[s.index].selectedMenuSaveEnabled)
                  || !schedulePartners[s.index]}
                loading={schedulePartners[s.index]
                  && schedulePartners[s.index].selectedMenuSaveLoading}
              >
                <SaveOutlined />
              </Button>
            </Tooltip>
            {/* <Tooltip title="Approve">
              <Button
                type="primary"
                style={{ marginLeft: '2px' }}
                onClick={() => onPropSet(s, s.index, 'menuApproved')}
                disabled={(typeof schedulePartners[s.index].menuApproved === 'string')}
                loading={schedulePartners[s.index].menuApprovedSaveLoading}
              >
                <CarryOutOutlined />
              </Button>
            </Tooltip> */}
          </div>
        );
      },
    },
    {
      title: 'Tax',
      key: 'tax',
      render: (s) => {
        let taxes;
        if (partner) {
          taxes = partner.menu ? partner.menu.taxes : [];
        } else if (schedule) {
          taxes = s.menu ? s.menu.taxes : [];
        }
        return (
          <div style={{ display: 'flex' }}>
            <Select
              style={{ width: '100%' }}
              defaultValue={s.taxId || ''}
              onChange={(value) => onPropChange(value, s, s.index, 'taxId')}
            >
              <Select.Option value="" key="">No Tax</Select.Option>
              {taxes?.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  <div>
                    <span>
                      {t.tax_data.name}
                      &emsp;
                    </span>
                    <span>{`${t.tax_data.percentage}%`}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
            <Button
              type="primary"
              style={{ marginLeft: '2px' }}
              onClick={() => onPropSet(s, s.index, 'taxId')}
              disabled={(schedulePartners[s.index]
                && !schedulePartners[s.index].taxIdSaveEnabled)
                || !schedulePartners[s.index]}
              loading={schedulePartners[s.index]
                && schedulePartners[s.index].taxIdSaveLoading}
            >
              <SaveOutlined />
            </Button>
          </div>
        );
      },
    },
    {
      title: 'Partner Fee',
      key: 'partnerFee',
      render: (s) => (
        <div style={{ display: 'flex' }}>
          <InputNumber
            defaultValue={s.partnerFee * 100 || 0}
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => Number(value!.replace('%', ''))}
            onChange={(value) => onPropChange(value!, s, s.index, 'partnerFee')}
          />
          <Button
            type="primary"
            style={{ marginLeft: '2px' }}
            onClick={() => onPropSet(s, s.index, 'partnerFee')}
            disabled={(schedulePartners[s.index]
              && !schedulePartners[s.index].partnerFeeSaveEnabled)
              || !schedulePartners[s.index]}
            loading={schedulePartners[s.index]
              && schedulePartners[s.index].partnerFeeSaveLoading}
          >
            <SaveOutlined />
          </Button>
        </div>
      ),
    },
    {
      title: 'Customer Fee',
      key: 'customerFee',
      render: (s) => (
        <div style={{ display: 'flex' }}>
          <InputNumber
            defaultValue={parseFloat((s.customerFee / 100).toFixed(2)) || 0}
            min={0}
            step={0.01}
            formatter={(value) => `$${value}`}
            parser={(value) => Number(value!.replace('$', ''))}
            onChange={(value) => onPropChange(value!, s, s.index, 'customerFee')}
          />
          <Button
            type="primary"
            style={{ marginLeft: '2px' }}
            onClick={() => onPropSet(s, s.index, 'customerFee')}
            disabled={(schedulePartners[s.index]
              && !schedulePartners[s.index].customerFeeSaveEnabled)
              || !schedulePartners[s.index]}
            loading={schedulePartners[s.index]
              && schedulePartners[s.index].customerFeeSaveLoading}
          >
            <SaveOutlined />
          </Button>
        </div>
      ),
    },
    {
      title: 'Check In / Out',
      key: 'checkInOut',
      render: (s) => (
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => onClickCheckInOut(s, 'partnerCheckIn')}
            disabled={(typeof s?.partnerCheckIn === 'string') || !s?.selectedMenu}
            type="primary"
            block
          >
            Check In
          </Button>
          <Button
            onClick={() => onClickCheckInOut(s, 'partnerCheckOut')}
            disabled={
              (typeof s?.partnerCheckOut === 'string')
              || (!(typeof s?.partnerCheckIn === 'string'))
              || !s?.selectedMenu
            }
            type="primary"
            block
          >
            Check Out
          </Button>
        </div>
      ),
    },
    {
      title: 'Menu Items',
      key: 'menuItems',
      render: (s) => (
        <Button
          type="primary"
          shape="circle"
          icon={<ExpandAltOutlined />}
          onClick={() => openMenuDialog(s)}
        />
      ),
    },
  ];

  if (partner) {
    columns.unshift(
      {
        title: 'Schedule',
        key: 'schedule',
        render: (s) => s.utc_start && s.utc_end
          && (
            <Link to={`/schedules/${s.schedule}`} onClick={() => setNavActiveTab('schedules')}>
              {`${moment(s.utc_start).format('h:mm a')} @ ${s.buildingName || ''} ${moment(s.utc_start).format('MMM Do')}`}
            </Link>
          ),
      },
    );
  } else if (schedule) {
    columns.unshift(
      {
        title: 'Partner',
        key: 'partner',
        render: (s) => (
          <Link to={`/partners/${s.food_truck}`} onClick={() => setNavActiveTab('partners')}>
            {s.display_name}
          </Link>
        ),
      },
    );
  }

  return (
    <>
      <Table
        onRow={(record: SchedulePartner) => ({
          onClick: () => { },
        })}
        dataSource={schedulePartners}
        columns={columns}
        rowKey="id"
        rowClassName="table-row"
        loading={isFetching}
        style={{ margin: '1rem' }}
      />
      {
        editingMenuSP && editingMenuSP.menu && (
          // @ts-ignore
          <Modal
            visible={editingMenuSP}
            title="Enable / Disable Menu Items"
            onOk={() => setEditingMenuSP(null)}
            onCancel={() => setEditingMenuSP(null)}
          >
            {
              editingMenuSP.menu.categories.map((cat, catIndex) => (
                <div
                  key={cat.name}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: '16px',
                  }}
                >
                  {cat.items.map((item, itemIndex) => (
                    <Switch
                      loading={itemDisableLoading[item.id]}
                      key={item.name}
                      checkedChildren={item.name}
                      unCheckedChildren={item.name}
                      checked={!item.isDisabled}
                      onClick={(e) => toggleItemDisabled(catIndex, itemIndex, e, item.id)}
                      style={{ margin: '8px 0px' }}
                    />
                  ))}
                </div>
              ))
            }
          </Modal>
        )
      }
    </>
  );
};

export default SchedulePartnerList;
