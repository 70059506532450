/* eslint-disable react/require-default-props, no-unused-vars, react/destructuring-assignment */
import React, {
  useEffect, useState, useRef,
} from 'react';
import {
  Table, message, Button, Alert, Modal, Input, Divider, Checkbox, Space,
} from 'antd';

import Highlighter from 'react-highlight-words';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';

import * as apiClient from '../../common/api-client';
import { Menu, MenuStatus, MenuItem } from '../../common/types';
import { navContext as _navContext } from '../../contexts/nav/navContext';
import MenuForm from './MenuForm';

const CheckboxGroup = Checkbox.Group;
const confirmModal = Modal.confirm;

const statusMap = {
  APPROVED: 'success',
  PENDING_REVIEW: 'warning',
  DISABLED: 'error',
  ADJUSTMENT_NEEDED: 'error',
};

const MenuList = () => {
  const [menus, setMenus] = useState<Menu[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [openMenuIndex, setOpenMenuIndex] = useState<number>(-1);

  const [checkedList, setCheckedList] = React.useState<string[]>();
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);

  const [approvedModalOpen, setApprovedModalOpen] = React.useState(false);
  const [plainOptions, setPlainOptions] = React.useState<string[]>([]);

  const [modalLoading, setModalLoading] = useState(false);

  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<any>();

  const loadMenus = async () => {
    try {
      const data: any = await apiClient.listAllMenus();
      setMenus(data.map((d, i) => ({ ...d, index: i })));
      setIsFetching(false);
    } catch (error: any) {
      message.error(error.message);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    loadMenus();
  }, []);

  const enableDisableMenu = async (menu, index: number, action: 'enable' | 'disable') => {
    const status = action === 'enable' ? MenuStatus.APPROVED : MenuStatus.DISABLED;

    setMenus([
      ...menus.slice(0, index),
      {
        ...menu,
        enableLoading: true,
      },
      ...menus.slice(index + 1),
    ]);

    const items = menu.categories.reduce((acc, val) => {
      acc.push(...val.items);
      return acc;
    }, []);

    await apiClient.updateMenu({
      ...menu,
      items,
      status,
    });

    setMenus([
      ...menus.slice(0, index),
      {
        ...menu,
        enableLoading: false,
        status,
      },
      ...menus.slice(index + 1),
    ]);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      // eslint-disable-next-line react/jsx-indent
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
      // eslint-disable-next-line indent
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) => (
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (text)),
  });

  const columns: any[] = [
    {
      title: 'Partner',
      key: 'partnerName',
      dataIndex: 'partnerName',
      ...getColumnSearchProps('partnerName'),
    },
    {
      title: 'Name',
      key: 'name',
      render: (m) => `${m.name || ''}`,
    },
    {
      title: 'Status',
      key: 'status',
      render: (m) => (
        <Alert
          style={{ textAlign: 'center', width: '205px' }}
          message={m.status}
          type={statusMap[m.status!] as any}
        />
      ),
      filters: [
        { text: 'Approved', value: 'APPROVED' },
        { text: 'Pending Review', value: 'PENDING_REVIEW' },
        { text: 'Disabled', value: 'DISABLED' },
        { text: 'Adjustment Needed', value: 'ADJUSTMENT_NEEDED' },
      ],
      defaultFilteredValue: ['PENDING_REVIEW'],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (t, m, i) => {
        if ([
          // MenuStatus.ADJUSTMENT_NEEDED,
          MenuStatus.PENDING_REVIEW,
        ].includes(m.status)) {
          return (
            <Button onClick={() => { setModalOpen(true); setOpenMenuIndex(m.index); }}>
              Review
            </Button>
          );
        }
        if (m.status === MenuStatus.APPROVED || m.status === MenuStatus.DISABLED) {
          return (
            <div style={{ display: 'flex' }}>
              <Button onClick={() => {
                const items: MenuItem[] = [];
                if (menus[i] && menus[i].categories) {
                  menus[i].categories!.forEach((category) => {
                    category.items!.forEach((item) => {
                      items.push(item);
                    });
                  });
                }
                setApprovedModalOpen(true);
                setOpenMenuIndex(m.index);
                setMenuItems(items);
                setPlainOptions(items.map((j) => j.name!));
                setCheckedList(items.map((j) => j.name!));
              }}
              >
                View
              </Button>
              <Button
                loading={m.enableLoading}
                style={{ marginLeft: '1rem' }}
                onClick={async () => {
                  confirmModal({
                    title: `Are you sure you want to ${m.status === MenuStatus.DISABLED ? 'enable' : 'disable'} this menu?`,
                    icon: <ExclamationCircleOutlined />,
                    onOk: () => enableDisableMenu(m, i, m.status === MenuStatus.DISABLED ? 'enable' : 'disable'),
                    onCancel() {
                    },
                  });
                }}
              >
                {m.status === MenuStatus.DISABLED ? 'Enable' : 'Disable'}
              </Button>
            </div>
          );
        }

        return null;
      },
    },
  ];

  const onMenuSave = async (menuSave: { items: MenuItem[], needsAdjustments: boolean }) => {
    try {
      setModalLoading(true);

      const status = menuSave.needsAdjustments ? MenuStatus.ADJUSTMENT_NEEDED : MenuStatus.APPROVED;

      const categories: any[] = [];

      menuSave.items.forEach((item) => {
        const index = categories
          .findIndex((category: any) => category.name === item.category) || -1;

        if (index > -1) {
          categories[index].items.push(item);
        } else {
          categories.push({
            name: item.category!,
            id: item.category_id!,
            items: [item],
          });
        }
      });

      const menu = {
        ...menus[openMenuIndex!],
        status,
        items: menuSave.items,
        categories,
      };

      await apiClient.updateMenu(menu);

      setMenus([
        ...menus.slice(0, openMenuIndex),
        menu,
        ...menus.slice(openMenuIndex! + 1),
      ]);
      message.success(menuSave.needsAdjustments ? 'Menu review submitted.' : 'Menu approved!');
      setModalLoading(false);
      setOpenMenuIndex(-1);
    } catch (error) {
      message.error('Error reviewing menu.');
      setModalLoading(false);
    }
    setModalOpen(false);
  };

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <>
      <Table
        dataSource={menus}
        columns={columns}
        rowKey="id"
        // onRow={(m: Menu, index) => ({
        //   onClick: () => { setModalOpen(true); setOpenMenuIndex(index!); },
        // })}
        rowClassName="table-row"
        loading={isFetching}
        style={{ margin: '1rem' }}
      />
      <MenuForm
        visible={modalOpen}
        onSubmit={onMenuSave}
        menu={openMenuIndex > -1 ? menus[openMenuIndex] : undefined}
        onCancel={() => {
          setModalOpen(false);
          setOpenMenuIndex(-1);
        }}
        loading={modalLoading}
      />
      {/* @ts-ignore */}
      <Modal
        visible={approvedModalOpen}
        title={menus[openMenuIndex] ? menus[openMenuIndex].name : ''}
        onCancel={() => {
          setApprovedModalOpen(false);
          setOpenMenuIndex(-1);
          setCheckedList([]);
        }}
        okText="Save"
        // onOk={onSaveMenu}
        destroyOnClose
        confirmLoading={modalLoading}
      >
        <div>
          <div style={{ display: 'flex' }}>
            <div>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                style={{ marginBottom: '1rem' }}
              >
                Select all
              </Checkbox>
              <CheckboxGroup
                options={plainOptions}
                value={checkedList}
                onChange={onChange}
                style={{ display: 'flex', flexDirection: 'column' }}
              />
            </div>
            <div style={{ margin: '38px 0 0 80px', width: '100%' }}>
              {menuItems.map((i) => (
                <div key={i.id} style={{ display: 'flex' }}>
                  <div style={{ marginRight: '1rem' }}>{i.category}</div>
                  <div style={{ display: 'flex', flex: 1 }} />
                  <div>{i.price}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MenuList;
