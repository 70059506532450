import React, { useState } from 'react';
import moment from 'moment';
import {
  Modal, Form, Input, Select, DatePicker, InputNumber,
} from 'antd';

const { Option } = Select;

const CouponForm = ({
  visible,
  loading,
  onCreate,
  onCancel,
}: { visible: boolean; loading: boolean; onCreate: Function; onCancel: any }) => {
  const [valuePrefix, setValuePrefix] = useState<string>('%');

  const [form] = Form.useForm();

  const onTypeChange = (value) => {
    switch (value) {
      case 'PERCENTAGE':
        setValuePrefix('%');
        break;
      case 'FIXED':
        setValuePrefix('$');
        break;
      default: break;
    }
  };

  return (
    // @ts-ignore
    <Modal
      visible={visible}
      title="Create a new coupon"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      okButtonProps={
        { loading }
      }
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate({
              ...values,
              isBillable: values.isBillable === 'Yes',
            });
            form.resetFields();
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <div style={{ display: 'flex' }}>
          <Form.Item
            name="code"
            label="Code"
            rules={[{ required: true, message: 'Please input a code!' }]}
            style={{ flex: 1, margin: '0 0.5rem 0 0' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[{ required: true, message: 'Please select a start date!' }]}
            style={{ flex: 1, margin: '0 0.5rem 0 0' }}
            initialValue={moment()}
          >
            <DatePicker />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', marginTop: '1rem' }}>
          <Form.Item
            name="maxUses"
            label="Max Uses"
            style={{ flex: 1, margin: '0 0.5rem 0 0' }}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="expiredDate"
            label="Expired Date"
            style={{ flex: 1, margin: '0 0.5rem 0 0' }}
          >
            <DatePicker />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', marginTop: '1rem' }}>
          <Form.Item
            name="couponType"
            label="Coupon Type"
            rules={[{ required: true, message: 'Please select a type!' }]}
            style={{ flex: 1, margin: '0 0.5rem 0 0' }}
            initialValue="PERCENTAGE"
          >
            <Select onChange={onTypeChange}>
              <Option value="PERCENTAGE">Percentage</Option>
              <Option value="FIXED">Fixed</Option>
            </Select>
          </Form.Item>
          {valuePrefix === '$' && (
            <Form.Item
              name="value"
              label="Value"
              style={{ flex: 1, margin: '0 0.5rem 0 0' }}
            >
              <InputNumber
                min={Number(0)}
                style={{ width: '100%' }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ''))}

              />
            </Form.Item>
          )}
          {valuePrefix === '%' && (
            <Form.Item
              name="value"
              label="Value"
              style={{ flex: 1, margin: '0 0.5rem 0 0' }}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={Number(0)}
                max={Number(100)}
                formatter={(value) => `${value}%`}
                parser={(value) => Number(value!.replace('%', ''))}
              />
            </Form.Item>
          )}
        </div>
        <div style={{ display: 'flex', marginTop: '1rem', width: '50%' }}>
          <Form.Item
            name="isBillable"
            label="Billable"
            rules={[{ required: true, message: 'Please select a type!' }]}
            style={{ flex: 1, margin: '0 0.5rem 0 0' }}
            initialValue="Yes"
          >
            <Select>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </div>
        <div />
      </Form>
    </Modal>
  );
};

export default CouponForm;
