/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import {
  Table, Input, Space, Button,
} from 'antd';
import { CSVDownload } from 'react-csv';
import {
  SearchOutlined, CheckCircleOutlined, CloseCircleOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import * as apiClient from '../../common/api-client';
import { Partner } from '../../common/types';

const PartnerList = () => {
  const [redirect, setRedirect] = useState<string>('');
  const [partners, setPartners] = useState<Partner[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<any>();
  const [downloadCSV, setDownloadCSV] = useState<boolean>(false);

  const loadPartners = async () => {
    const data = await apiClient.listPartners();
    setPartners(data);
    setIsFetching(false);
  };

  useEffect(() => { loadPartners(); }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
        // eslint-disable-next-line react/jsx-indent
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput.current = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
        // eslint-disable-next-line indent
      ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) => (
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (text)),
  });

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      // eslint-disable-next-line no-nested-ternary
      // sorter: (a, b) => ((!a.name || !b.name) ? -1 : a.name.localeCompare(b.name) ? 1 : 0),
      ...getColumnSearchProps('name'),

    },
    {
      title: 'Square',
      key: 'square',
      render: (p) => {
        if (p.merchant && p.merchant.locationId && p.merchant.locations) {
          const index = p.merchant.locations
            .findIndex((l) => l.id === p.merchant.locationId);
          if (index > -1) {
            return p.merchant.locations[index].name;
          }
        }
        return <CloseCircleOutlined />;
      },
    },
    {
      title: 'Connected',
      key: 'connected',
      render: (p) => (p.merchant && p.merchant.createdDate
        ? moment(p.merchant.createdDate).format('MMM Do YY') : <CloseCircleOutlined />),
      sorter: (a, b) => (
        // eslint-disable-next-line no-nested-ternary
        (a.merchant && a.merchant.createdDate)
          ? -1 : (b.merchant && b.merchant.createdDate) ? 1 : 0
      ),
    },
    {
      title: 'Synced',
      key: 'synced',
      sorter: (a, b) => (
        // eslint-disable-next-line no-nested-ternary
        (a.merchant && a.merchant.updatedDate)
          ? -1 : (b.merchant && b.merchant.updatedDate) ? 1 : 0
      ),
      render: (p) => (p.merchant && p.merchant.updatedDate
        ? moment(p.merchant.updatedDate).format('MMM Do YY') : <CloseCircleOutlined />),
    },
    {
      title: 'Menu',
      key: 'menu',
      sorter: (a, b) => (
        // eslint-disable-next-line no-nested-ternary
        (a.menu && a.menu.categories && a.menu.categories)
          ? -1 : (b.menu && b.menu.categories && b.menu.categories) ? 1 : 0
      ),
      render: (p) => (p.menu && p.menu.categories && p.menu.categories
        ? (<CheckCircleOutlined />) : (<CloseCircleOutlined />)),
    },
  ];

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={`/partners/${redirect}`} />;
    }
    return null;
  };

  const renderCSVDownload = () => {
    if (downloadCSV) {
      return <CSVDownload data={partners} target="_blank" />;
    }
    return null;
  };

  return (
    <>
      {renderRedirect()}
      {renderCSVDownload()}
      <div className="index-menu-tab-input list-actions">
        {!isFetching && (
          <Button
            onClick={() => {
              setDownloadCSV(true);
              setTimeout(() => setDownloadCSV(false), 1000);
            }}
            type="primary"
            className="export-button"
          >
            Export
          </Button>
        )}
      </div>
      <Table
        onRow={(record: Partner) => ({
          onClick: () => setRedirect(record.id),
        })}
        dataSource={partners}
        /* @ts-ignore */
        columns={columns}
        rowKey="id"
        rowClassName="table-row"
        loading={isFetching}
        style={{ margin: '1rem' }}
      />
    </>
  );
};

export default PartnerList;
